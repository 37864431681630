

.overlayLoader {
    position: absolute;
    left: 0%;
    top: 0%;
    height: 100vh;
    width: 100vw;
    background: #000000a6;
    z-index: 1;
}

.loaderContianer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 20000;
}

.loaderContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
 }

.loader {
    border: 10px solid #B88C00;
    border-radius: 50%;
    border-top: 10px solid #002060;
    width: 70px;
    height: 70px;
    position: relative;
   /* left: calc(50% - 15px);*/
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    margin:0 auto;
    margin-bottom: 20px;
}

.loaderInfo{
    font-weight: 600;
    color: #f3f3f3;
    
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}