.checkout-table {
  width: 75%;
  letter-spacing: 0;
  &.automate {
    .cart-calc > div span:last-child {
      margin-right: 0;
    }
  }
}
.payment-sec {
  width: 25%;
  margin-left: 1%;
  border-radius: 8px;
  padding: 15px;
  font-family: "Lato", sans-serif !important;
  letter-spacing: 0;
  .card-container {
    padding: 15px;
  }
  .cash-container {
    padding: 0 15px;
  }
  .card-detail {
    width: 75%;
    h5 {
      font-size: 1.12rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
  .form-control-orderleads {
    height: 36px;
    border-radius: 4px;
  }
  .availableCreditAmount {
    color: $primary-font-inactive;
  }
}

.checkout-container {
  @include respond-to(md) {
    flex-direction: column;
    .checkout-table {
      width: 100%;
    }
    .payment-sec {
      width: 100%;
    }
  }
}

.sc-table {
  .leadtable__body__card__mobilecontent__row1__cell {
    min-height: 55px;
    margin-bottom: 0.5rem;
    &:last-child {
      .leadtable__body__card__mobilecontent__row1__cell--data {
        flex-direction: row;
        text-align: left;
      }
    }
  }
  .leadtable__body__card__mobilecontent__row1__cell--header {
    text-align: left;
  }
  .leadtable__body__card__mobilecontent__row1__cell--data {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    min-width: 140px;
    justify-content: flex-start;
  }
  @include respond-to(md) {
    .table-footr {
      flex-direction: column;
      .cart-calc {
        margin-top: 2rem;
        > div {
          display: flex;
        }
      }
    }
  }
}

.sticky-bottom {
  @include respond-to(md) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background-white;
    padding: 0.5rem 25px;
    z-index: 1;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25);
    .btn-row {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      > button {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5rem;
      }
    }
  }
}
