.multiselect-container {
  .searchBox {
    width: 100%;
  }

  .search-wrapper {
    .chip {
      border-radius: 0;
      background-color: $shaded-color;
      color: $primary-font;

      .custom-close {
        font-style: initial;
        font-weight: 400;
        margin-left: 0.3rem;
        font-size: 0.95rem;
        color: $primary-action;
        cursor: pointer;
      }
    }
  }

  .optionContainer {
    list-style-type: none;

    .highlightOption {

      // background-color: transparent;
      // color: $primary-font;
      &:hover {
        background-color: $primary-action-onhover;
        color: $background-white;
      }
    }

    .option:hover {
      background-color: $primary-action-onhover;
    }
  }
}