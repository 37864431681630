.page-title-font {
  font-size: 32px;

  @include respond-to(md) {
    font-size: 24px;
  }
}

.user-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header,
  .user {
    display: flex;
  }

  .cell {
    flex: 1;
    padding: 12px 15px;
    font-size: 16px;
    word-break: break-all;
  }

  .header {
    color: $primary-font;
    font-weight: 600;
  }

  .header .cell {
    text-align: left;
    word-break: keep-all;
    word-wrap: break-word;
  }

  .user .cell {
    min-height: 72px;
    display: flex;
    align-items: center;
    background: $background-white;
    border-bottom: 1px solid $border-lines;
    text-align: left;

    &.no-border-btn {
      background-color: $background-white;
      border-color: transparent;
      border-bottom-color: $border-lines;
      color: $gradient-afternoon !important;

      &:active {
        border: none;
        border-bottom: 1px solid $border-lines;
        outline: none;
      }

      &.disable-button {
        cursor: not-allowed !important;
        opacity: 0.6;
      }
    }
  }

  .cell[data-label="Name"],
  .cell[data-label="Location (City, State)"],
  .cell[data-label="Organizations"] {
    word-wrap: break-word;
    word-break: keep-all;
  }

  @include respond-to(xl) {

    .cell[data-label="Account Created"],
    .cell[data-label="Last Login"],
    .cell[data-label="Organizations"] {
      display: none;
    }
  }

  @include respond-to(lg) {

    .cell[data-label="Account Created"],
    .cell[data-label="Last Login"],
    .cell[data-label="Organizations"],
    .cell[data-label="Status"] {
      display: none;
    }
  }

  @include respond-to(xxsm) {

    .cell[data-label="Account Created"],
    .cell[data-label="Last Login"],
    .cell[data-label="Organizations"],
    .cell[data-label="Status"],
    .cell[data-label="Location (City, State)"] {
      display: none;
    }
  }
}

.dot {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50%;
  display: inline-block;
}

.success-main {
  background-color: $success-main;
}

.error-main {
  background-color: $error-main;
}

.text-disabled {
  background-color: $text-disabled;
}

.selected-option {
  background-color: $primary-action !important;
}

.form-text-area {
  min-height: 40px;
  max-height: 150px;
}