.newvoucher {
  &__header {
    flex-wrap: wrap;

    &__text {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 34px;
      letter-spacing: -0.01em;
      color: #22315c;
    }
  }

  &__content {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 200px;
    background-color: $background-white;
    border: 1px solid #c7ccd1;
    border-radius: 3px;
    padding: 30px;

    &__amounts {
      &__section {
        display: flex;
        flex-wrap: wrap;

        &__input {
          @media screen and(max-width: '768px') {
            padding-left: 0px;
            margin-top: 5px;
          }
        }

        &--amount {
          margin-right: 10px;

          input[type="radio"] {
            display: none;
          }

          label {
            width: 100%;
            height: 48px;
            background: $background-white;
            border: 1px solid $integrity-gray-light-2;
            border-radius: 4px;
            padding: 12px;
            text-align: center;
            font-weight: 400;
            cursor: pointer;
          }

          input:checked + label {
            background-color: $background-body;
          }
        }
      }
    }

    &__settings {
      margin-top: 10px;
      border-bottom: 1px solid $border-lines;
    }

    &__prices {
      margin-top: 20px;
    }
  }
}

.customoutlinetext-group {
  padding: 12px;
  border: 1px solid $integrity-gray-light-2;
  border-radius: 4px;
  min-width: 120px !important;
  height: 48px;
}

.value-amount-input {
  padding: 12px;
  border: 1px solid $integrity-gray-light-2;
  border-radius: 4px;
  height: 48px;
  width: 100%;
}

.value-amount-input > input {
  outline: 0;
  border: none;
}

.customoutlinetext-group > label {
  position: absolute;
  top: -8px;
  left: 20px;
  background-color: white;
  color: #7f7f8a;
  font-size: 10px;
}

.customoutlinetext-group > input {
  outline: 0;
  border: none;
}

.min-height-40px {
  min-height: 40px;
}

.outline-none {
  outline: none;
}

.invalidcustomoutlinetext-group {
  padding: 3px;
  border: 2px solid #d32028;
  border-radius: 5px;
  min-width: 120px !important;
}

.invalidcustomoutlinetext-group > label {
  position: absolute;
  top: -8px;
  left: 20px;
  background-color: white;
  color: #d32028;
  font-size: 10px;
}

.invalidcustomoutlinetext-group > input {
  outline: 0;
  border: none;
}

.invalidAmountEntered {
  background-color: #fff2f2;
  border: 2px solid #d32028 !important;
  color: #d32028;
}

.invalidAmountEntered > input {
  background-color: #fff2f2;
}

.invalidAmountEntered > label {
  color: #d32028;
}

.availableCreditText {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #3c4b64;
}

.availableCreditSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.invalidAmount {
  color: #d32028;
}

.statesList {
  list-style: none;

  li {
    width: 150px;
  }
}

.dfd-options {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;

  > * {
    @extend .ml-4;
  }

  // Remove ml-4 class from Options
  > .ml-4 {
    @if flex-wrap==wrap {
      @extend .ml-4;
    }
  }
}

.radio-btn-16px {
  height: 16px;
  width: 16px;
}

.value-button-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.create-voucher-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 24px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}
.create-voucher-details-section {
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0px;
  }
}

.view-voucher-settings-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
