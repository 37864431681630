@import "../../../scss/variables";
@import "../../../scss/mixin";

.faq-page-title {
  color: $header-font;
  font-size: 2rem;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
.accordion_item {
  .button {
    text-align: left;
    width: 100%;
    margin: 0 !important;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: $header-font;
    line-height: 30px;
    background-color: $background-white;
    border: transparent;
    border-bottom: 1px solid $border-lines;
    min-height: 80px;
    padding: 0 20px;
    img.title-icon {
      margin-top: -5px;
      max-width: 32px;
      margin-right: 0.5rem;
    }
    svg {
      color: $primary-font;
      width: 32px;
    }
    .chevron {
      font-size: 1.5rem;
      color: $gradient-afternoon;
      width: 24px;
    }
    &.sub-section-button {
      background-color: $background-modal;
    }
  }
  &.active {
    border-bottom: 1px solid $border-lines;
    .button {
      border-bottom-color: transparent;
    }
    &:last-child {
      border-bottom-color: transparent;
    }
  }
  &:first-child {
    .button {
      border-radius: 4px 4px 0 0;
    }
  }
  &:last-child {
    .button {
      border-bottom-color: transparent;
      border-radius: 0 0 4px 4px;
    }
  }
}
.section_wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in;
  background-color: $background-white;
  .section {
    background-color: $background-white;
    .question-section {
      margin: 0 1rem 0;
      padding: 1rem;
      background-color: $background-modal;
      border-bottom: 1px solid $border-lines;
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-bottom-color: transparent;
        margin-bottom: 1rem;
        border-radius: 0 0 4px 4px;
      }
      h3 {
        font-family: "Lato", sans-serif;
        font-size: 1.25rem;
        color: $title-blue;
        line-height: 24px;
        margin-bottom: 0.5rem !important;
        &:first-child {
          border-top-color: transparent;
        }
      }
      p {
        font-family: "Lato", sans-serif;
        font-size: 1rem;
        color: $primary-font;
        line-height: 24px;
        margin-bottom: 0.5rem !important;
      }
    }
  }
  &.sub-section {
    overflow: visible;
    .section {
      padding-bottom: 1px;
    }
    .sub-section{
      .section {
        background-color: $background-modal;
      }
      .question-section{
        background-color: $background-modal;
        padding: 1rem 0;
      }
    }
  }
}

.accordion_item:last-child > .section_wrapper > .question-section:last-child p {
  margin-bottom: 2rem !important;
}

.expand-button,
.collapse-button{
  cursor: pointer;
  color: $gradient-afternoon;
  font-size: 1rem;
}

.highlight-keyword{
  color: $background-white;
  border-radius: 4px;
  &.morning {
    background: $gradient-morning;
  }
  &.afternoon {
    background: $gradient-afternoon;
  }
  &.evening {
    background: $gradient-evening;
  }
}