.dtable {
  width: 100%;
  border-collapse: collapse;
  border: none;

  tbody tr {
    border-bottom: 1px solid red !important;
  }
}

@media screen and (max-width: 767px) {
  table thead {
    display: none;
  }

  table tbody,
  table tbody tr {
    display: block;
    width: 100%;
  }

  table tbody tr th::before,
  table tbody tr td::before {
    content: attr(data-title);
    display: block;
  }

  table tbody tr td,
  table tbody tr th {
    display: block;
    width: 50%;
    float: left;
  }

  table tbody tr {
    margin-bottom: 16px;
    float: left;
    clear: both;
  }
}