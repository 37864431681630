.coupon-container {
  display: grid;
  padding: 8px 0;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 24px;
  margin-top: 0.5rem !important;
  margin-bottom: 1rem;
  @media (max-width: 1200px) {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
  }
}

.card-section{
  background: $background-white;
  border-radius: 8px;
  padding: 16px;
  height: auto;

  .form-control{
    height: 40px;
    border: 1px solid $border-lines;
    border-radius: 4px;
  }

  .css-yk16xz-control{
    min-height: 40px !important;
  }
}

.p-text{
  color: $tertiary-font;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.basic-chip {
  display: inline-block;
  width: auto;
  padding: 6px 10px;
  background: $background-default;
  color: $primary-font;
  font-size: 13px;
  margin: 2px 4px 2px 0px;
  border-radius: 4px;
}
