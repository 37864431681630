.btn-secondary{
  background: $background-white;
  border: 0px;
  width: auto;
  white-space: normal;
  border-radius: 4px;
  &:hover, &:active{
    background: $background-white;
  }
}
