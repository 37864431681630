.walmart-modal-dialog {
  margin-top: 10% !important;
}

.walmart-modal-content {
  border: 0px;
  border-radius: 8px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.flex-wrap-md {
  @include respond-to(md) {
    flex-wrap: wrap;
  }
}

.h-65vh {
  height: auto;
  min-height: 65vh;

  @include respond-to(md) {
    height: auto;
  }
}

.top-0 {
  top: 0 !important;
  max-width: 800px !important;
}

.required-select-field{
  border: 1px solid $error-500 !important;
  border-radius: 4px;
}