.table-container {
  display: grid;
  width: 100%;
  color: #434a51;
  border-radius: 8px;
}

.table-row {
  display: contents;
}

.table-cell {
  text-align: center;
  min-height: 52px;
  vertical-align: middle;
  padding: 16px;
}

.data-cell {
  border-bottom: 1px solid #00000012;
  min-height: 72px;
  background-color: white;
}

.link-align,
.text-align,
.leadGroups-align,
.date-align,
.status-align,
.voucherBalance-align,
.checkbox-align,
.email-align,
.upline-align {
  text-align: left;
}

.table-row > .data-cell {
  word-break: break-all;
}

.link-align {
  overflow-wrap: break-word;
}

.leadGroups-align,
.number-align {
  min-width: 150px;
}

.amount-align {
  padding-right: 20%;
}

.discount-align,
.number-align,
.revoke-align,
.amount-align {
  justify-content: end;
}

.leadName-align,
.leadType-align {
  text-align: justify;
}

.header > .discount-align,
.number-align,
.revoke-align,
.amount-align {
  text-align: right;
}

.header-cell {
  font-weight: bold;
}

.link-decoration:hover {
  text-decoration: none !important;
  color: $gradient-afternoon;
}

@media (max-width: 768px) {
  .link-align {
    overflow-wrap: break-word;
  }
  .table-row > .data-cell {
    word-break: break-all;
  }
}
.expanded-content {
  grid-column: 1 / -1;
  padding: 16px;
  background-color: $background-body;
}

.chevron-align {
  justify-content: end;
}

.icon-up,
.icon-down {
  transition: transform 0.3s ease-in-out;
}

.icon-up {
  transform: rotate(180deg);
}

.icon-down {
  transform: rotate(0deg);
}

.expanded-row-content {
  height: 100%;

  &__section {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 1 1 33.33%;
      box-sizing: border-box;

      @include respond-to(md) {
        flex: 1 1 100%;
      }
    }

    > div:nth-child(3n + 1):nth-last-child(1) {
      flex: 1 1 100%;
    }

    > div:nth-child(3n + 1):nth-last-child(2),
    > div:nth-child(3n + 1):nth-last-child(2) ~ div {
      &:nth-child(3n + 1):nth-last-child(2) {
        flex: 1 1 33.33%;

        @include respond-to(md) {
          flex: 1 1 100%;
        }
      }

      &:nth-child(3n + 2) {
        flex: 1 1 66.66%;

        @include respond-to(md) {
          flex: 1 1 100%;
        }
      }
    }
    &.vendor-credits {
      > div {
        flex: 1 1 10%;
      }
      > div:nth-child(3n) {
        flex: 1 1 80%;
      }
      > div:nth-child(5n) {
        flex: 1 1 90%;
      }
      > div:last-child {
        flex: 1 1 100%;
      }
    }
  }
}

.limited-height {
  max-height: 170px;
  overflow: hidden;
  &.vendor-credits {
    max-height: 400px;
  }
}

.mapped-items {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 1 50%;

    @include respond-to(md) {
      flex: 1 1 100%;
    }
  }
}

.email-tooltip-text .tooltip-inner {
  font-size: $fs-12;
  font-weight: 500;
}
