@media (max-width: 768px) {
  .mobile-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    margin-top: -1%;
  }
  .mobile-background.morning {
    background-image: url('../../assets/Images/greeting-morning.svg');
  }

  .mobile-background.afternoon {
    background-image: url('../../assets/Images/greeting-afternoon.svg');
  }

  .mobile-background.evening {
    background-image: url('../../assets/Images/greeting-evening.svg');
  }
}

.bg-greet {
  height: 250px;
  width: auto;
  border-bottom-left-radius: 50% 100%;
  @include respond-to(lg) {
    width: 40%;
    text-align: center;
  }
}

.bg-greet.morning {
  background-color: $gradient-morning;
}

.bg-greet.afternoon {
  background-color: $gradient-afternoon;
}

.bg-greet.evening {
  background-color: $gradient-evening;
}
