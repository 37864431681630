@import 'src/scss/_variables.scss';

.modal-contact-support {
  .modal-dialog {
    max-width: 500px;
    top: 25%;
  }
  .modal-header {
    border-color: transparent;
    padding: 24px;
    font-size: 32px;
    color: $integrity-navy;
    border-bottom: 1px solid $divider;
  }
  .modal-body {
    padding: 24px;
    background-color: $background-default;
  }
  .support-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .body-content-container {
    background-color: white;
    border-radius: 8px;
    margin-top: 8px;
  }
  .support-item {
    display: flex;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid $divider;
  }
  .support-item:last-child {
    border-bottom: none;
  }
  .support-item a:hover,
  .support-item a:focus {
    color: inherit;
  }
  .support-item button {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
  }
  .support-item button:hover {
    background: none;
    border: none;
    text-decoration: underline;
  }
  .support-item-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 20px;
    background: $background-body;
    height: 32px;
    width: 32px;
  }
  @media screen and (max-width: 767px) {
    .modal-header {
      padding: 1rem 1rem 0.5rem 0;
    }
    .modal-body {
      padding: 0 2rem 2rem;
    }
    .support-sec {
      margin-top: 1.5rem;
      span {
        &:first-child {
          font-size: 1rem;
        }
      }
    }
    .support-title {
      font-size: 1.125rem;
    }
  }
}
