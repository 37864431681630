.nav-bar-container {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 767px) {
    position: relative;
    flex-direction: row;
  }
}

.tab-container {
  @media screen and (min-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.disabled-border {
  border-bottom: 1px solid $text-disabled;
}
