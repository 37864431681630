.viewlead {
  margin-top: -20px;

  &__card {
    background: $background-white;
    border: none !important;
    border-radius: 8px !important;

    &--border-none {
      border: none;
      border-radius: 0.45rem !important;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: none !important;

      &--title {
        font-size: 24px;
        font-weight: 400;
        align-items: left;
        margin-bottom: 0;
        text-align: left;
        color: $header-font;
        letter-spacing: 0;

        @include respond-to(xl) {
          font-size: 18px;
        }
      }
    }
  }

  &__cardbody {
    min-height: 100px;
    padding-left: 5px;
    padding-top: 10px;
    display: flex;
    align-items: stretch;
    border-top: 0.5px solid $border-lines !important;

    &__gradientborder {
      width: 5px;
      border-radius: 120px;
    }

    &__data {
      padding-left: 30px;
      width: 100%;
    }
  }

  &__arrowIcon {
    position: relative;
    transition: transform 0.3s ease-in-out;

    &--icon {
      font-size: 16px;
      cursor: pointer;
      color: $primary-action;
    }

    &.open {
      transform: rotate(180deg);
    }

    &.closed {
      transform: rotate(0deg);
    }
  }
}

.lead {
  &__body {
    &__menu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-right: 10px;

      &__block {
        display: flex;
        align-items: center;

        @include respond-to(md) {
          flex-wrap: wrap;
        }

        &--header {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $header-font;
        }
      }

      &__items {
        display: flex;
        align-items: center;

        @include respond-to(md) {
          flex-wrap: wrap;
        }

        &--item {
          margin-left: 20px;

          img {
            height: 20px;
            width: 20px;
            object-fit: cover;
          }

          span {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $primary-action;
          }
        }
      }

      &__more {
        position: relative;
        background-color: $icon-selection-color;
        border-radius: 4px;
        padding: 4px;

        &__dropdown {
          position: absolute;
          top: 105%;
          right: 0;
          background-color: $background-white;
          filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
          border-radius: 3px;
          padding: 8px;
          min-width: 12.6rem;
          z-index: 1;

          &__option {
            padding: 5px;
            margin: 4px;
            border-radius: 3px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $primary-font;

            &:hover {
              background-color: $row-highlight;
              color: $primary-action;
            }
            &.no-credit-li:hover {
              cursor: not-allowed;
            }
          }
        }

        &__dropdown2 {
          position: absolute;
          top: 30%;
          right: 0;
          background-color: $background-white;
          filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
          border-radius: 3px;
          padding: 8px;
          min-width: 12.6rem;
          z-index: 1;

          &__option {
            padding: 5px;
            margin: 4px;
            border-radius: 3px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $primary-font;

            &:hover {
              background-color: $row-highlight;
              color: $primary-action;
            }
          }
        }
      }
    }
  }

  &__headerInfo {
    display: flex;
    align-items: center;

    @include respond-to(md) {
      flex-wrap: wrap;
    }

    &--title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: $primary-font;

      &:first-child {
        margin-left: 0px !important;
      }

      @include respond-to(md) {
        margin-left: 0 !important;
        margin-top: 5px;
      }

      span {
        text-transform: initial;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

.leaddetails {
  padding-top: 10px;

  &__row {
    display: flex;
    flex-wrap: wrap;

    &__item {
      padding: 10px;

      &--label {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $header-font;
      }

      &--info {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: $primary-font;
      }
    }
  }
}

.flex-15 {
  flex: 0 0 15%;
}

.flex-25 {
  flex: 0 0 25%;

  @include respond-to(sm) {
    flex: 0 0 100%;
  }
}

.flex-33 {
  flex: 0 0 33%;

  @include respond-to(sm) {
    padding-top: 10px;
  }
}

.flex-44 {
  flex: 0 0 44%;
}

.flex-50 {
  flex: 0 0 50%;

  @include respond-to(sm) {
    flex: 0 0 100%;
  }
}

.flex-85 {
  flex: 0 0 84%;
}

.flex-100 {
  flex: 0 0 100%;
}

.tabs-container {
  width: 280px;
}

.tabs {
  display: flex;
  position: relative;
}

.tab {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  color: $primary-action;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  line-height: 14px;
  font-weight: 400;
}

.tab.active {
  font-weight: 700;
}

.tab-item {
  color: $tertiary-font;
  border-bottom: 2px solid $tertiary-font;
  padding: 8px 16px;
}

.tab-active {
  border-bottom: 4px solid $gradient-afternoon;
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  // commented to removed Life tab - to be reverted in future
  // background-color: #f5f6fa;
  background-color: $primary-action;
  border-radius: 5px;
}

.indicator-2,
.indicator-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% / 2 - 10px);
  height: 2px;
  background-color: $primary-action;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.3s ease;
}

.indicator-2 {
  transform: translateX(0);
  margin: 0 5px;
}

.indicator-1 {
  transform: translateX(100%);
  margin: 0 5px 0 15px;
}

.tab.active ~ .indicator-2,
.tab.active ~ .indicator-1 {
  opacity: 1;
}

.tr {
  width: 2px;
  height: 22px;
  background-color: $border-lines;
  margin-top: 10px;
  border-radius: 10px;
}

.medicare_redirect {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $tertiary-font;
  text-align: right;
}

.bg-morning {
  background: $gradient-morning;
}

.bg-afternoon {
  background: $gradient-afternoon;
}

.bg-evening {
  background: $gradient-evening;
}

.h-2rem {
  height: 2rem;
}

.mobile_products {
  display: flex;
  align-items: center;

  .transactionshistory__selectedOptions__option {
    margin-left: 10px;
  }

  @include respond-to(md) {
    flex-wrap: wrap;

    .transactionshistory__selectedOptions__option {
      margin: 5px 0px;
    }
  }
}

.highlight-reminder {
  background-color: $background-body !important;
  border-radius: 0px !important;
}

.rc-modal-header {
  font-size: 24px;
  font-weight: 400;
  align-items: left;
  justify-content: left;
  margin-bottom: 0;
  text-align: left;
  color: $header-font;
}

.modal-header .close {
  color: $primary-action !important;
}

.smallSizeBtn {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notesInfoText {
  padding: 5px 0;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $primary-font-inactive;
}

.creditaction-banner {
  margin: 20px 0;
  border: 1px solid $border-lines;
  background-color: $background-body;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: left;

  &__section1 {
    svg {
      font-size: 32px;
      color: $primary-action;
    }
  }

  &__section2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__label {
      margin-left: 20px;
      color: $primary-font;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 400;

      @include respond-to(md) {
        margin-left: 10px;
      }

      span {
        font-weight: 700;
      }
    }

    &--cta {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}

.note-modal-body {
  label {
    margin-bottom: 0;
    color: $primary-font;

    span {
      color: $primary-font-inactive;
    }
  }
}

.trusted-form-tooltip .tooltip-inner {
  max-width: 300px;
  p {
    font-size: 0.75rem;
    margin-bottom: 0 !important;
  }
}
.trusted-form-disable span {
  color: $primary-font !important;
}
.trusted-form-link {
  text-decoration: none !important;
  color: $primary-action;
}

.grid-container {
  display: grid;
  gap: 24px;
}

.grid-container > :last-child {
  margin-bottom: 24px;
}

/* Individual Grid Items */
.grid-item {
  display: flex;
  justify-content: start;
  align-items: center;
}

.lead-header-container {
  display: flex;
  gap: 16px;
}

.lead-header-container > :last-child {
  margin-left: auto;
}

.lead-header-data-item {
  display: flex;
  gap: 24px;
}

.lead-header-certifications {
  display: inline-flex;
  gap: 8px;
}

.lead-header-certification-image {
  max-width: 24px;
  max-height: 24px;
}
