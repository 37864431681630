.create-ads {
  display: flex;
  height: 600px;

  &__verticalCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &__dot {
      width: 4px;
      height: 4px;
      margin: 5px;
      background-color: $carousel-dot;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: $background-body;
      }

      &.active {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $primary-action;
      }
    }
  }

  &__ad {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    max-height: 600px;

    &__info {
      &__template {
        display: flex;
        flex-direction: row;
        height: 95%;

        @include respond-to(md) {
          flex-direction: column;
        }

        &__image {
          flex: 0.25;
          height: 100%;
          background-color: $background-white;

          @include respond-to(md) {
            flex: 1;
          }

          img {
            padding: 20px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &__content {
          flex: 0.75;
          padding: 20px;

          @include respond-to(md) {
            flex: 1;
          }
        }
      }

      .bg-selectedCreative {
        background-color: $background-body;
        color: $secondary-font;
        margin-top: 20px;
        width: 100%;
        height: 500px;
        border-radius: 8px;
        flex-direction: column;

        @include respond-to(md) {
          height: auto;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      .bg-nonSelectedCreative {
        margin-top: 20px;
        width: 100%;
        height: 500px;
        background-color: $background-modal;
        color: $secondary-font;
        border-radius: 8px;
        flex-direction: column;

        @include respond-to(md) {
          height: auto;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.desktop-buttons {
  display: flex;

  @include respond-to(md) {
    display: none;
  }
}

.mobileFixedBtns {
  display: none;

  @include respond-to(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 13px 25px;
    background: $light-blue;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
    transition: bottom 0.3s ease-in-out;
    z-index: 10;
  }
}

.select-creativeTabs {
  width: 200px;
  margin-left: 20px;
}

.social_media_chip {
  background-color: $warning-200;
  color: $leadCENTER-Gold;
}

.direct_mail_chip {
  background-color: $background-pill;
  color: $primary-action;
}

.borderedSection {
  margin-left: 20px;
  flex-grow: 1;
  height: 1px;
  background-color: $border-lines;
}

.creative-template-image {
  height: auto;
  width: 274px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}