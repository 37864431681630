.info-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  gap: 24px;
}

.info-flex {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.info-grid-column {
  display: grid;
  // grid-template-columns: repeat(4, 1fr); /* 4 equal columns */ 
  gap: 16px;
}

.info-label {
  color: $integrity-navy;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.info-value {
  color: $tertiary-font;
  font-size: 16px;
}

.reminders-description {
  border: 1px solid $components-input-outlined-enabledBorder;
  border-radius: 4px;
  padding: 12px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .info-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .info-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .info-grid {
      grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}

.client-notes-grid-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
}
