@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');

// If you want to override variables do it here
@import 'variables';

//All Mixins goes here.
@import './mixin';

//utility classes written under this
@import './utilities';

//import and use layout classes
@import './layout';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';

@import './bootstrap-overrides';

//Import all new scss files here to access it any where in the Project
@import '../app/containers/OrderLeads/orderleads';
@import './components/createvouchers';
@import './components/reviewegift';
@import './components/leadImport';
@import './components/transactionsHistory';
@import './components/productCartItem';
@import './components/datatable';
@import './components/checkout.scss';
@import './components/sorting';
@import './components/vieworeditlead';
@import './components/multiSelectDropdown';
@import './components/_progresssteps';
@import './components/adcampaigns';
@import './components/walmartProgram';
@import './components/userPaymentCard';
@import './components/statusIndicators';
@import '../app/containers/OrderLeads/automatePopup';
@import '../app/containers/FAQ/faq';
@import './components/banners';
@import './components/userProfiles';
@import './components/customKpi';
@import './components/coupons';
@import './components/dynamicTable';
@import './components/noresults';
@import './components/vendorRequests';
@import './components/toaster';
@import './components/agentWelcomeModal';
@import './components/pageNavBar';
@import './components/pageUtility';
@import './components/agentReferralModal';
@import './components/collapsiblecard';
@import './components/leaddetails';
@import './components/greet';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.zindex-1201 {
  z-index: -2 !important;
}
