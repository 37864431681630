.simple-header {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;

  &__title {
    a {
      display: flex;
      padding: 1rem; // increase hit area
      margin-left: -1rem;
      max-width: calc(293px + 2rem);
    }
  }

  @media (min-width: 480px) {
    padding: 0.5rem 2rem;
    margin-top: 0;
  }
  @media (min-width: 768px) {
    justify-content: space-around;
    padding: 1rem 2.5rem;
  }
}

.ils-logo {
  width: 125px;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.username{
  color:#7f7f8a !important;
}
