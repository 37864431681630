.cartitem {
  display: flex;
  align-items: center;
  border: 1px solid $border-lines;
  border-radius: 5px;
  color: $background-white;
  height: 37px;

  &__number {
    width: 63px;

    input {
      width: 70px;
      height: 100%;
      outline: none;
      border: none;
      color: $primary-font;
    }

    &__disabled {
      cursor: not-allowed;
      opacity: 0.6;
      color: $primary-action;
      font-size: 10px;
    }

    &--icon {
      color: $primary-action;
      font-size: 10px;
      cursor: pointer;
    }
  }

  &__carticon {
    height: 105%;
    padding: 6px;
    background-color: $primary-action;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &--disabled {
      height: 105%;
      padding: 6px;
      background-color: $button-disabled-color;
      cursor: not-allowed;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }
}