@mixin respond-to($breakpoint) {
  @if $breakpoint == xxsm {
    @media (max-width: $breakpoint-xxsm) {
      @content;
    }
  } @else if $breakpoint == xsm {
    @media (max-width: $breakpoint-xsm) {
      @content;
    }
  } @else if $breakpoint == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if $breakpoint == xxl {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
  } @else if $breakpoint == largeres {
    @media (max-width: $breakpoint-largeres) {
      @content;
    }
  } @else if $breakpoint == highres {
    @media (max-width: $breakpoint-highres) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin generate-top-classes($max-size) {
  @for $i from 0 through $max-size {
    .top-#{$i}px {
      top: $i * 1px !important;
    }
  }
}

@mixin generate-margin-top-classes($max-size) {
  @for $i from 0 through $max-size {
    .margin-top-#{$i}px {
      margin-top: $i * 1px !important;
    }
  }
}

@mixin generate-max-height-classes($max-size) {
  @for $i from 0 through $max-size {
    .max-height-#{$i}vh {
      max-height: $i * 1vh !important;
    }
  }
}
