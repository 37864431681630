//TOASTER STYLE
.Toastify__toast-container{
  min-height: 48px !important;
}

.Toastify__toast{
  min-height: 48px !important;
  color: $primary-font;
}

.Toastify__toast--success {
  background: $components-alert-success-background !important;
}

.Toastify__toast--info {
  background: $components-alert-info-background !important;
}

.Toastify__toast--warning {
  background: $background-warning !important;
}

.Toastify__toast--error {
  background: $components-alert-error-background !important;
}

.Toastify__toast-close {
  border: none;
  background: none;
  align-self: center !important;
  padding-right: 0.5rem !important;
  > svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.Toastify__close-button {
  border: none;
  background: none;
  align-self: center !important;
  padding-right: 0.5rem !important;
  > svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.severity-icon-0 {
  color: $success-main;
}
.severity-icon-10 {
  color: $info-main;
}
.severity-icon-90
{
  color: $warning-main;
}
.severity-icon-100 {
  color: $error-main;
}
