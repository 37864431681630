.reviewegift {
  padding: 15px;
  background-color: $background-white;
  border: 1px solid #C7CCD1;
  border-radius: 3px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--title {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.01em;
      color: #2371A4;
    }

    &--arrow {
      cursor: pointer;
      display: none;

      @media screen and(max-width: '592px') {
        display: flex;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__form {
      width: 40%;

      @media screen and(max-width: '620px') {
        width: 100%;
      }

      &__section {
        width: 250px;

        &--input {
          width: 80% !important;
        }

        &--invalidText {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: $fs-12;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: $color-invalid;
        }
      }
    }

    &__details {
      @media screen and(max-width: '620px') {
        width: 100%;
        padding-top: 10px;
      }

      &__generalInfo {
        padding-bottom: 10px;
        border-bottom: 1px solid #333333;
      }

      &__paymentInfo {
        padding-top: 10px;
      }
    }
  }
}

.invalidInput {
  background: #FFF2F2;
  border: 2px solid $invalid-border;
  border-radius: 5px;

  &.input {
    background-color: #FFF2F2 !important;

    .was-validated &:valid {
      background-image: none;
    }

    .was-validated &:invalid {
      background-image: none;
    }
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  outline: none;
  box-shadow: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  outline: none;
  box-shadow: none !important;
}

.reviewsearchBtn {
  all: unset;
}

.overlay-conatiner {
  max-height: 20vh;
  overflow-y: scroll;
  padding: 15px 0;
}

.overlay-conatiner::-webkit-scrollbar {
  width: 10px;
}

.overlay-conatiner::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 20px;
}

.active-color {
  color: $primary-action;
}

.revoked-color {
  color: $invalid-border;
}

.fs-16{
  font-size: 16px;
}