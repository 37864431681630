.page-utility-container {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 767px) {
    position: relative;
    flex-direction: row;
  }
}

.page-utility-search-bar {
  width: 50%;
  .quick-search {
    width: 100%;
    input[type="text"],
    input[type="text"]:focus-visible {
      border-color: $border-lines;
      border-right-color: transparent;
      outline: none;
    }
    input::placeholder {
      color: $primary-font-inactive !important;
    }
    button {
      background-color: $background-white;
      border-color: $border-lines;
      border-left-color: transparent;
    }
  }
  @include respond-to(md) {
    width: 100%;
  }
}
