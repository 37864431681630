.communication-banners {
  top: 0;
  width: 100%;
  z-index: 10;
  background: $gradient-afternoon;
  .carousel-indicators {
    @include respond-to(lg) {
      bottom: -10px;
    }
  }

  .close-banner {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    color: $background-white !important;
    font-size: 1.5rem;
  }

  .button-icon {
    font-size: 1.75rem;
    padding-left: 0.3rem;
  }

  .carousel-indicators li {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    opacity: 0.3;
  }

  .carousel-indicators .active {
    background-color: $background-white;
    opacity: 1;
  }

  &__banner {
    padding: 1.5rem;
    height: 152px;
    overflow: hidden;
    @extend .align-center;

    @include respond-to(md) {
      height: 220px;
    }

    &--img {
      width: 260px;
      height: 100%;
      display: block;
      flex: 0.2;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      @include respond-to(md) {
        display: none;
      }
    }

    &__content {
      flex: 1;
      padding-right: 2rem;
      margin-left: 260px;
      @extend .flex-between;

      @include respond-to(md) {
        flex-wrap: wrap;
        margin-left: 0;
        padding-right: 0;
        justify-content: center;
        align-items: center;
      }
      .banner-text {
        color: $background-white;
        display: inline-block;
        width: 100%;
        text-align: left;
        @include respond-to(md) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
}

.blocked-banner {
  width: 100%;
  z-index: 10;
  background: $error-200;
  @include respond-to(md) {
    position: absolute;
  }
  &__reason {
    min-height: 62px;
    display: flex;
    align-items: center;
    position: relative;

    &__icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 62px;
      height: 100%;
      width: 60px;
      font-size: 18px;
      color: $error-main;
    }

    &__info {
      margin-left: 65px;
      padding: 8px 0px;
      color: $primary-font;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
