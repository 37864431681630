.vendor-request {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more[data-title]::after {
  content: attr(data-title);
  position: absolute;
  bottom: 95%;
  left: 50%;
  color: $primary-action !important;
  transform: translateX(-50%);
  padding: 5px;
  border-radius: 4px;
  white-space: wrap;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  font-size: 12px;
  font-weight: 500;
}

.more[data-title]:hover::after {
  opacity: 1;
}
