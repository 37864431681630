@import "../../scss/variables";
@import "../../scss/mixin";
a {
  text-decoration: underline;
}

.content-frame {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &.register-bu{
    background-image: url('../../assets/Images/multi-bu-bg.jpg') !important;
  }
}

.container {
  display: flex;
  flex-direction: column;

  .sub-container {
      position: relative;
  }

  .provider-input {
    background: $background-white;
    border: 1px solid #cbd5e1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    width: 359px;

    &[value],
    ::placeholder {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #64748b;
      padding-left: 16px;
    }
  }

  .label-name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #475569;
    padding-bottom: 5px;
  }

  .label-dropdown {
    width: 359px;
    overflow-y: "scroll";
    padding: 0;
    list-style-type: none;
    position: "relative";
    background: $background-white;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .label-close {
    position: absolute;
    top: 12px;
    left: 315px;
    transform: scale(0.8);
    color: #64748b;
  }

  .provider-dropdown {
    max-height: 359px;
    overflow-y: auto;
  }

  .provider-section {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    padding: 10px;

    &.selected {
      background-color: #f1f5f9;
    }
  }

  .provider-name {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #313336;
  }

  .provider-info {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #64748b;
  }

  .selected-provider {
    background: #f2f9ff;
    border-radius: 4px;
    width: 345px;
    padding: 16px 0px 16px 16px;
  }
}

.register-bu .container--size-small{
  background: transparent;
}

.container--size-small {
  background: $background-white;
  color: #1b1b1b;
  border: 2px solid #e8eaef;
  box-sizing: border-box;
  border-radius: 0;
  border-left-style: none;
  border-right-style: none;
  padding: 45px 2rem;
  max-width: 480px;
  border-color: transparent;

  @media (min-width: 480px) {
    padding: 45px 60px;
    border-radius: 12px;
    border-left-style: solid;
    border-right-style: solid;
  }
}

.v2 {
  background: $background-modal;
  color: #1b1b1b;
  font-family: "Lato", sans-serif;

  .simple-header {
    .logo {
      color: #1b1b1b;
      width: 172px;
    }
    .ils-logo {
      width: 255px;
    }
  }

  .simple-footer {
    color: #6a6a6a;

    .link {
      color: #6a6a6a;
    }
  }

  .text {
    &--secondary {
      color: #6a6a6a;
    }

    &-sm {
      letter-spacing: -0.25px;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    &-xl {
      font-size: 1.875rem;
      line-height: 3rem;
    }
  }

  .link {
    color: $primary-action;

    &:hover {
      color: $primary-action;
      text-decoration: underline;
    }

    &--secondary {
      color: #6a6a6a;
    }

    &--dark-underline {
      text-decoration: underline;
  }
}

  
  .auth-notification {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;
    background: #f1f8ff;
    border-radius: 2px;

    svg {
      width: 2rem;
      margin: 0.25rem 0.625rem 0 0;
      height: auto;
    }

    a {
      color: $primary-action;
    }
  }

  .form__fields > * + .form__submit {
    margin-top: 4rem;
  }
}

.btn--flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 380px){
  .btn--flex{
    margin-top: -25px;
  }
}

.btn-v2 {
  display: inline-flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  min-width: 6rem;
  background: $primary-action;
  border-radius: 2px;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.1875rem;
  color: #fff;
  cursor: pointer;
  border: 0;
}
.btn-v2-disabled {
  display: inline-flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  min-width: 150px;
  background: $primary-action;
  border-radius: 2px;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.1875rem;
  color: #fff;
  cursor: pointer;
  border: 0;
}
.btn-v2-disabled:disabled,
.btn-v2-disabled[disabled]{
  background-color: #cccccc;
  color: #fff;
}

.icon-btn {
  border: 0;
  background: none;
  width: 3rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: inherit;
  cursor: pointer;

  &--bump-right {
      margin-right: -0.5rem;
  }
}

.inputElement {
  border: 0;
  background: none;
  font-size: 1rem;
  min-width: 0;
  flex: 1 0;
  padding: 0.5rem;
  height: 38px;
  line-height: 38px;
}

.form {
  &__fields {
    border: 0;
    margin: 0;
    padding: 0;

    > * + * {
      margin-top: 1rem;
    }
    > * + .form__submit {
      margin-top: 2.5rem;
      display: flex;
      justify-content: center;
    }
    .custom-txt{
      width: 100%;
      text-align: right;
    }
    &--constrained {
      max-width: 22.5rem;
    }
  }
  &-input {
    &__header {
      margin-bottom: 0;
    }

    &__focus-banner {
      position: relative;
      opacity: 1;

      .form-tip {
        background-color: transparent;
        color: #1b1b1b;
        padding: 1rem 0;
        font-size: 12px;
        line-height: 18px;
      }
    }

    &--readonly {
      & > .form-input__input {
        border-color: #c7ccd1;

        input {
          background-color: #e3e5e7;
          color: #8d8d8d;
        }
      }
    }

    &__input {
      &-actions button {
        position: absolute;
        top: -2.25rem;
        right: 0rem;
      }

      .showPassword {
        position: absolute;

        top: -1.9rem;
        right: 0;
        justify-content: flex-end;
        min-width: 6rem;
        font-size: 14px;
        color: $primary-action;

        svg {
          color: $primary-action;
          margin-right: 0.375rem;
        }
      }
    }
  }
}
.label {
  display: block;
  font-size: 1rem;
  line-height: 1.5;
}

.form-input {
  &__header {
    display: flex;
    margin-bottom: 0.5rem;

    > .label {
      margin-right: auto;
    }
  }

  &__input {
    border: 2px solid #e3e6ec;
    background: $background-white;
    border-radius: 0.25rem;
    display: flex;
    box-sizing: border-box;
    position: relative;
    transition: background-color 0.2s ease, border-color 0.2s ease;

    &:focus-within {
      border-color: #236c9e;
      z-index: 1;
    }

    input,
    select,
    textarea {
      border: 0;
      background: none;
      font-size: 1rem;
      min-width: 0;
      flex: 1 0 0;
      padding: 0.5rem;
      height: 38px;
      line-height: 38px;

      &:focus {
        outline: none; // focus style defined on parent
      }
    }

    input:read-only {
      background-color: transparent;
      color: #8f99a3;
    }

    textarea {
      height: auto;
      line-height: (24/16);
      min-height: 10rem;
      max-height: 25rem;
      resize: vertical;
    }
  }

  &__date-wrap {
    flex: 1 0 auto;

    > input {
      width: 100%;
    }
  }

  &__input-actions {
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    padding: 0 0.375rem;

    > * + * {
      margin-left: 0.5rem;
    }

    &:empty {
      display: none;
    }
  }

  &__error {
    visibility: hidden;
    font-size: 0.75rem;
    line-height: 2;
    min-height: 1.5rem;
    color: #d86a66;
  }

  &--error {
    > .form-input__input {
      border-color: #d86a66;
    }
    > .form-input__error {
      visibility: visible;
    }
  }

  &--success {
    > .form-input__input {
      border-color: #94d199;
    }
  }

  &__focus-banner {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  &__input--show-banner > input:focus + &__focus-banner {
    opacity: 1;
  }

  &--readonly {
    > .form-input__input {
      border-color: #8f99a3;
      background-color: #e8eaef;
    }
  }

  &__icon {
    color: #e8eaef;
  }

  &__icon--main {
    display: inline-flex;
    width: 2.25rem;
    align-items: center;
    justify-content: center;
  }

  &__icon--main + input {
    padding-left: 0;
  }
}

.form-input--invert {
  .form-input__input {
    background: rgba(255, 255, 255, 0.25);
    color: $background-white;
    border-color: rgba(255, 255, 255, 0);

    &:focus-within {
      border-color: $background-white;
      background-color: rgba(255, 255, 255, 0.35);
    }

    & > input::placeholder {
      color: #a6a3a1;
    }

    &:focus-within > input::placeholder {
      color: $background-white;
    }
  }
  .form-input__icon {
    color: $background-white;
  }
}

.form-input--select {
  .form-input__input > select {
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
    padding-top: 0;
    padding-right: 3rem;

    &::-ms-expand {
      display: none;
    }
  }
  .form-input__input-actions {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.form-tip {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 2;
}

.list-basic {
  > li:before {
    content: "\2022";
    margin-right: 0.25em;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s; // hack to disable MOST of chrome's autofill styling
}

.showPasswordIcon {
  justify-content: flex-end;
  min-width: 6rem;
  color: $primary-action;
  outline: none;
}

.svg {
  fill: $primary-action;
  margin-right: 0.375rem;
}

button:focus {
  outline: none;
}

.buWordWrap {
  word-wrap: break-word;
}

.label.custom-text{
  margin-top: 1rem;
  margin-bottom: 0;
  span{
    display: inline-block;
    margin-top: 1rem;
    font-style: italic;
    font-size: 0.75rem;
  }
  &+div{
    margin-top: 0 !important;
    margin-bottom: 1.5rem !important;
  }
}

.bu-header{
  margin-bottom: 3rem !important;
  color: $header-font;
}

.custom-footer-multibu{
  margin-top: 5rem;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .ftr-links{
    text-decoration: none !important;
    cursor: pointer;
    &:hover,&:visited,:active{
      color: $primary-font;
      text-decoration: none !important;
    }
  }
  .separator{
    padding: 1.5rem;
    @include respond-to(md){
      padding: 0.5rem;
    }
  }
  @include respond-to(md){
    padding: 30px;
    flex-direction: column;
    >div{
      margin-top: 1rem;
    }
  }
}

.bg-blue{
  background-color: $background-header-nav;
}
.custom-header-multi-bu{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $background-white;
  padding: 0 20px;
  height: 100px;
  margin-bottom: 10rem;
  img{
    max-width: 250px;
    @include respond-to(md){
      max-width: 150px;
    }
  }
}

.colorWhite{
  text-decoration: none !important;
  &:hover{
    color: $background-white;
    text-decoration: none !important;
  }
}