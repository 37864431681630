.payment-card {
  background-color: $background-modal;
  border: 1px solid $border-lines;
  border-radius: 8px;
  float: right;

  @include respond-to(sm) {
    margin-top: 10px;
  }
}

.flex-05 {
  flex: 0.5;

  @include respond-to(sm) {
    flex: 1;
  }
}

.walmart-organization-select {
  width: 90%;

  @include respond-to(sm) {
    width: 100%;
  }
}