.modal-automate-dialog {
  max-width: 950px;
  width: 100%;
  top: 5%;

  .modal-header {
    background-color: $header-font;

    color: $background-white;

    svg {
      max-width: 100px;
      width: 100%;
      font-size: 4rem;
    }

    h3 {
      margin: 0 !important;
      font-size: 3rem !important;
      text-align: center;
      width: 100%;
      padding: 3rem 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-body {
    padding: 20px;

    .automate-section-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .col-automate {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 30%;
      border: 1px solid $border-light;
      padding: 10px;
      min-height: 260px;

      p {
        font-size: $fs-18;
        color: $header-font;
        text-align: center;

        svg {
          font-size: 4rem;
          color: $primary-action;
        }
      }

      .automated-leads {
        font-size: 4rem;
        font-weight: lighter;
        color: $primary-action;
      }
    }

    .bg-body {
      background-color: $background-body;
      margin: 0 !important;
      padding: 10px;
      min-height: 125px;
    }

    .small-text {
      font-size: 12px;
      color: $header-font;
      display: inline-block;
    }

    .automate-section-two {
      margin: 2rem 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid $border-light;

      .vertical-line {
        min-height: 200px;
        height: 100%;
        border: 1px solid $border-light;
        margin: 1rem 5rem;
      }
    }

    .col-automate-two {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      h3 {
        text-align: center;
        font-size: 1.5rem;
        margin: 0 !important;
        color: $header-font;
      }

      p {
        text-align: center;
        font-size: 1rem;
        color: $header-font;
        min-height: 25px;
        margin: 0 !important;

        svg {
          font-size: 1rem;
          color: $primary-action;
        }

        .tool-tip {
          display: inline-block;
        }
      }

      .cost {
        font-size: 3rem;
        color: $primary-font;
        margin: 1rem 0 !important;

        &.approximate {
          color: $primary-action;
        }
      }

      >div {
        min-height: auto;
        padding: 10px;
      }

      .bg-body {
        padding: 15px 30px;
      }
    }

    .footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @include respond-to(md) {
    width: 90%;
    margin: 0 auto;

    .automate-section-one,
    .automate-section-two {
      flex-direction: column !important;
    }

    .modal-header {
      padding: 1rem;

      h3,
      svg {
        font-size: 1.5rem !important;
      }

      svg {
        max-width: 40px;
      }
    }

    .col-automate {
      width: 90% !important;
      margin-bottom: 1rem;
    }

    .vertical-line {
      min-height: 1px !important;
      width: 70%;
      margin: 2rem auto !important;
    }

    .cost {
      font-size: 2rem !important;
    }

    .col-automate-two {
      width: 90%;

      .bg-body {
        padding: 10px !important;
        width: 100%;
        margin-top: 1rem !important;
      }
    }

    .small-text {
      margin-bottom: 1rem;
    }
  }
}