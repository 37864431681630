@import 'src/scss/variables';

// Here you can add other styles
.border-bottom_leads {
  box-shadow: 0 2px 2px -2px #ced2d8;
}

.lead-group-button {
  width: 80%;
}

.backtoCartHover {
  display: flex;
}

.backtoCartHover :hover {
  color: #017e9d;
}

.lead-group-button:hover {
  color: #ffff;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.Lead-type {
  color: #727578;
}

.Lead-header {
  height: 85px;
}

.description-column {
  display: flex;
  justify-content: space-between;
}

// payment screen
.box {
  width: 100%;
  margin: 50px;
  display: table;
}

.box .box-row {
  display: table-row;
}

.box .box-cell {
  display: table-cell;
  width: 50%;
  padding: 10px;
}

.box .box-cell.box1 {
  text-align: justify;
}

.box .box-cell.box2 {
  text-align: justify;
}

.modal-AddToCart-dialog {
  top: 30%;
  max-width: 600px;
}

.lead-alert-notification {
  color: #c4c4c4;
  font-size: 12px;
}

.ellipsis {
  display: inline-block;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
  white-space: nowrap;
}

.product-group-row {
  border-color: #fff;
  margin-bottom: 15px;
}

.product-group-row-header {
  border-color: #fff;
  padding-left: 0;
  margin-left: 1rem;
}

.product-type-header {
  margin: 25px 0 25px 2rem;
  font-size: 16px;
  color: #005cc8;
  font-weight: 600;
}

.product-type-header-icon {
  font-size: 18px;
  margin-right: 20px;
}

.lead-group-container {
  border-top: solid 1px #d8dbe0;
}

.expand-collapse {
  cursor: pointer;
}

.expand-collapse-icon {
  height: 18px !important;
  width: 18px !important;
}

.lead-types-header {
  font-size: 14px;
  font-weight: 600;
  color: #3c4b64;
  margin-left: 1rem;
}

.lead-group-header {
  margin-left: 2rem;
}

.lead-info {
  margin-left: 2rem;
  align-items: center;
}

.checkbox-alignment {
  align-items: baseline;
}

.checkbox-alignment-leadType {
  align-items: center;
}

.collapse-all-mobile {
  margin-right: 20px;
}

.showHideMap {
  color: #2370a4;

  > span {
    cursor: pointer;
  }
}

.county-states {
  border-radius: 4px;
  background-color: #5b6166;
  padding: 1rem 0;
  color: #fff;
  width: 100%;

  > span {
    padding-left: 0.5rem;

    span {
      font-size: 0.77rem;
      opacity: 0.75;
    }
  }
}

.icon {
  cursor: pointer;
  color: $primary-action;
}

@media (max-width: 768px) {
  .desktop-icon {
    display: none;
  }

  .lead-group-header-mobile {
    margin-left: 0px;
  }

  .mobile-version-header {
    flex-direction: column;
  }

  .lead-type-header-mobile {
    display: none !important;
  }

  .lead-info {
    margin-left: 0rem !important;
  }

  .lead-property-mobile {
    display: none !important;
  }

  .leads-input-mobile {
    flex-direction: column;
  }

  .item-quantity-mobile {
    align-self: flex-end;
    font-size: 10px;
    color: #3c4b64;
  }

  .product-group-row-header {
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  .mobile-icon {
    display: none;
  }

  .item-quantity-mobile {
    display: none;
  }

  .lead-info-mobile {
    display: none;
  }
}

.clearall {
  width: 200px;
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
  align-items: center;
  color: $primary-action;
  cursor: pointer;

  @media screen and(max-width: '592px') {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.orderleads-settings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__selections {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;

    @media screen and(max-width: '592px') {
      width: 100%;
      flex-wrap: wrap;
    }

    &__dd {
      width: 100%;

      &--title {
        font-weight: 600;
        color: $color-title;
      }

      &--optional {
        font-weight: 400;
      }
    }

    &__dd2 {
      width: 100%;
      margin-left: 15px;

      &--title {
        font-weight: 600;
        color: $color-title;

        span {
          font-weight: 400;
        }
      }

      @media screen and(max-width: '592px') {
        margin-left: 0px;
        margin-top: 5px;
      }
    }
  }

  &__addBtn {
    width: 100%;
    margin-top: 15px;
    display: flex;

    @media screen and(max-width: '592px') {
      width: 100%;
    }
  }
}

.map-component-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  background-color: $background-white;
  border-radius: 0 0 10px 10px;
  padding: 0 1.5rem 1.5rem;

  &.orderleads-map {
    height: 450px;
  }
}

.mapfiltersection-hint {
  background-color: $background-white;
  padding: 0 25px 15px 25px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $tertiary-font;
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip-header {
  white-space: nowrap;
  color: #2371a4 !important;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  border-radius: 6px;
  top: 100%;
  left: 50%;
  margin-left: -120px;
  position: absolute;
  z-index: 1;
  background: #f5f6fa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

.line {
  border-top: 1px solid $border-lines;
}

.card {
  color: black;
  border-radius: 0% !important;
}

.overlayDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 25px 50px;
  background-color: rgba($color: $background-white, $alpha: 0.9);
  z-index: 1;
  border-bottom-left-radius: 8px;

  &__container {
    height: 95%;
    display: flex;

    &__s1 {
      display: block;

      @include respond-to(xl) {
        display: none;
      }
    }

    &__s2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $background-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-action;
      border-radius: 10px;
    }
  }
}

.width-100 {
  width: 100%;
}

.width-20 {
  transition: width 0.5s;

  @include respond-to(xl) {
    display: none;
  }
}

.mobileoverlay {
  display: none;
  position: relative;
  background-color: $background-white;
  padding: 0 1.5rem 1.5rem;

  @include respond-to(xl) {
    display: block;
  }

  &__filters {
    max-width: 300px;
    width: 100%;

    @include respond-to(md) {
      max-width: none;
    }
  }
}

.sm-width-75 {
  @media screen and(max-width: '592px') {
    width: 75%;
  }
}

.indication-arrow {
  height: 174px;
  width: 174px;
  border-radius: 50%;
  background-color: $background-body;
  background-color: rgba($color: $primary-action, $alpha: 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &--icon {
    color: $primary-action;
    font-size: 87px;

    @include respond-to(xl) {
      transform: rotate(90deg);
    }
  }
}

.indication {
  margin-top: 20px;
  margin-left: 20px;
  flex-direction: column;

  @include respond-to(xl) {
    margin-left: 0;
    flex-direction: column-reverse;
  }

  h3 {
    color: $header-font;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 20px;
    text-align: center;

    @include respond-to(xl) {
      font-size: 18px;
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
}

.leadtable {
  padding-bottom: 20px;
  border-radius: 8px;

  &__headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $primary-font;

    &--cell1 {
      flex: 0.1;
      display: flex;
      align-items: center;
    }

    &--cell {
      flex: 0.2;
      display: flex;
      align-items: center;
    }

    @include respond-to(md) {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__mobileheatmapcheckbox {
      display: none;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $primary-font;

      @include respond-to(xl) {
        display: flex;
      }
    }

    &__sectioncard {
      background-color: $background-white;
      padding: 15px 0px 0px 50px;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: $primary-font;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      @include respond-to(xl) {
        padding: 5px 0px 5px 0px;
        background-color: transparent;
      }
    }

    &__card {
      background-color: $background-white;
      padding: 15px;
      border-bottom: 0.5px solid $border-lines;
      transition: transform 0.2s ease-in-out;

      @include respond-to(xl) {
        padding: 16px;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid $border-lines;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(md) {
          display: none;
        }

        &--name {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.06em;
          color: $primary-font;
        }

        &__gemstonelead {
          padding: 5px 25px 5px 6px;
          background: $background-body;
          border-radius: 3px;
          display: flex;
          align-items: center;

          &--name {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.06em;
            color: $primary-font;
          }
        }

        &--cell2 {
          flex: 0.02;
          display: flex;
          align-items: center;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $primary-font;
        }

        &--cell1 {
          flex: 0.1;
          display: flex;
          align-items: center;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $primary-font;
        }

        &--cell {
          flex: 0.2;
          display: flex;
          align-items: center;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $primary-font;
        }
      }

      &__mobilecontent {
        display: none;

        @include respond-to(md) {
          display: flex;
          flex-direction: column;

          &__row1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 10px;

            @include respond-to(xl) {
              margin-top: 0;
            }

            &__cell {
              display: flex;
              flex-direction: column;

              @media (max-width: 284px) {
                flex-basis: 100%;
              }

              &--header {
                font-family: 'Lato', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                text-align: right;
                color: $header-font;
                margin-bottom: 0.25rem;
              }

              &--data {
                font-family: 'Lato', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;
                justify-content: end;
                text-align: right;
                color: $primary-font;
              }

              &:last-child {
                margin-top: 1rem;
                min-height: 58px;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }

      &__expandedcontent {
        margin-top: 5px;
        padding: 10px 15px;
        background: $background-body;
        border-radius: 4px;

        &__items {
          display: flex;
          align-items: center;
          column-gap: 48px;
          row-gap: 16px;
          flex-wrap: wrap;

          @include respond-to(xl) {
            flex-direction: column;
            align-items: flex-start;
          }

          &__item {
            &--header {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: $header-font;
            }

            &__info {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: $primary-font;
            }
          }
        }
      }

      .flex-column-between {
        min-height: 115px;
      }
    }
  }
}

.arrowIcon {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;

  &--icon {
    color: $primary-action;
  }

  &.open {
    transform: rotate(180deg);
  }

  &.closed {
    transform: rotate(0deg);
  }
}

.no-leads-in-area {
  letter-spacing: 0;
  cursor: pointer;
  color: $primary-action;
  font-weight: 700;
  font-size: 1rem;
  margin-left: 0.5rem;

  svg {
    margin-right: 0.3rem;
  }
}

.leads-request-modal {
  font-size: 1rem;

  .form-group {
    margin: 0 auto 1.5rem;
    max-width: 400px;
  }

  label {
    color: $primary-font;
  }

  .css-1wa3eu0-placeholder {
    color: $primary-font-inactive;
  }

  h3 {
    color: $header-font;
    margin-bottom: 0;
  }

  .req-text {
    font-size: 12px;
    padding-left: 0.5rem;
    line-height: 1.2;
    margin-bottom: 0 !important;
  }

  .required {
    color: $error-500;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
}

.purchase_today {
  input[type='checkbox'] {
    width: 16px;
    height: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $gradient-afternoon;
    border-radius: 3px;
    background-color: $background-white;
  }

  /* Custom checkmark using background-image when checkbox is checked */
  input[type='checkbox']:checked {
    background-color: $gradient-afternoon;
    background-image: url('/assets/Images/check-mark.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  input[type='checkbox']:disabled {
    border: 1px solid $primary-font-inactive;
    border-radius: 3px;
    background-color: $background-white;
  }

  label {
    line-height: 19px;
  }
}

.flex-60 {
  flex: 0.6;
}

.flex-40 {
  flex: 0.4;
}

.menu-width {
  min-width: 264px;
}
