.width-80 {
    width: 80%;

    @media screen and(max-width: '592px') {
        width: 100%;
    }
}

.lead_importer {
    &__header {
        display: flex;
        justify-content: space-between;
    }
}

.leadimporter {
    &--title {
        font-weight: 300;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: -0.01em;
        color: #3C4B64;
    }

    &--templateBtn {
        margin-left: 30px;
        color: $background-white;

        &:hover {
            text-decoration: none !important;
        }

        @media screen and(max-width: '768px') {
            margin-left: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 50% !important;
        }

        @media screen and(max-width: '452px') {
            margin-left: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100% !important;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;

        @media screen and(max-width: '452px') {
            width: 100%;
            flex-wrap: wrap;
        }

        &__section {
            width: 50%;

            @media screen and(max-width: '452px') {
                width: 100%;
            }
        }

        &__section2 {
            width: 50%;
            margin-left: 30px;

            @media screen and(max-width: '452px') {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    &__status {
        &__info {
            display: flex;
        }
    }
}

.invalidtypeImport {
    border: 2px solid red;
    background-color: $background-error;
}

.inavildFileText {
    margin-left: 5px;
    color: $error-500;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
}

.fc-import {
    color: $import-text-color;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.optional-text {
    color: $muted-text-color;
    font-size: 14px;
    font-style: italic;
}

.custom-primary-btn {
    width: 100px;
    max-width: 100% !important;
}

.dropzone {
    min-width: 50px;
    min-height: 20px;

    @media screen and(max-width: '452px') {
        padding: 0 20px 0 20px;
    }

    &__uploadIcon {
        font-size: 80px;
        font-weight: 100;
        color: $primary-action;
    }

    &__accepted-text {
        color: $primary-action;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
    }
}

.rejectedZone {
    border: 2px dashed $error-500 !important
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: -2px !important;
    border-width: 0 0.6rem 0.6rem;
    border-bottom-color: $primary-font !important;
}

.disabled-bg {
    background-color: #a7a8a9 !important;
    opacity: 0.65;
    width: 100%;
    max-width: 100%;
    border: none !important;
    outline: 0 !important;
    color: white;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;

    &:hover {
        color: $secondary-font;
        opacity: 1;
    }
}

.mappingTextContent {
    width: 50%;

    @media screen and(max-width: '592px') {
        width: 100%;
    }
}

.unassined__section {
    display: flex;
    margin-top: 20px;

    @media screen and(max-width: '592px') {
        flex-wrap: wrap;
    }

    &--header {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #3C4B64;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__optional-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 100px;
            background: $background-white;
            border: 1px solid #2371A4;
            border-radius: 3px;
            margin: 5px;
            padding: 2px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.06em;
            color: #3C4B64;
        }

        &__required-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 100px;
            background: $background-white;
            border: 1px solid $error-500;
            border-radius: 3px;
            margin: 5px;
            padding: 2px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.06em;
            color: #3C4B64;
        }

        &__refresh {
            margin-left: 5px;
            margin-right: 5px;
            color: $primary-action;
            cursor: pointer;
        }
    }
}

.mappingTable {
    margin-top: 20px;
    width: 100%;
    overflow: auto;
    padding-bottom: 5px;

    &__th {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #2371A4;
        color: $background-white;
        border: 1px solid #2371A4;
        border-radius: 3px;
    }

    &::-webkit-scrollbar {
        height: 7px;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        margin-right: 2px;
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        cursor: pointer;
    }

    table {
        border-collapse: collapse;
        border: 0;

        tr td:first-child {
            padding-left: 10px;
            min-width: 300px !important;
        }

        td {
            padding: 10px;
            min-width: 300px !important;
        }

        tr th:first-child {
            padding-left: 0px;
            min-width: 300px !important;
        }

        th {
            padding: 10px 10px 10px 0px;
            min-width: 300px !important;
            width: 100% !important;
        }

        tbody {
            tr {
                border: 1px solid #D8DBE0 !important;
            }
        }
    }

    &__footer {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__file {
            display: flex;
            align-items: center;
            justify-content: center;

            &--name {
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.01em;
                color: #3C4B64;
            }
        }
    }
}

[draggable=true] {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

[draggable=true]:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.draggingItem {
    opacity: 0.5;
}

.solid-bg {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.drag-entered {
    -webkit-box-shadow: 0 0 10px #999;
    -moz-box-shadow: 0 0 10px #999;
    box-shadow: 0 0 10px #999;
    border-radius: 2px;
}

.file__placeholder {
    padding: 10px 50px 0px 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.01em;

    color: $secondary-font;
}