.refer-agent {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 115px;
  border: 8px solid $background-default;
  border-left: none;
  border-right: none;
  font-size: 16px;
  overflow: hidden;
  @extend .flex-start;
  align-items: center;
  background-color: $background-white;
  &-icon {
    width: 60px;
    height: inherit;
    @extend .flex-center;
    background-color: $info-main;
    color: $background-white;
    svg {
      font-size: 24px;
    }
  }
  &-text {
    height: inherit;
    @extend .flex-column-start;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    font-weight: 500;
    .button-no-border {
      padding-left: 0;
      font-weight: 600;
    }
  }
  @include respond-to(md) {
    width: 250px;
    position: relative;
    overflow: visible;
    overflow-y: clip;
  }
}

#agent-referral-modal {
  .ilc-modal-content {
    border-radius: 8px;
    width: 510px;
    > div:first-child {
      background-color: $integrity-navy;
      border-radius: 8px 8px 0 0;
      .ilc-modal-header {
        width: 100%;
        @extend .flex-start;
        color: $background-white;
        .title-text {
          margin-left: 1rem;
        }
      }
      .ilc-modal-header + div {
        button span {
          svg {
            color: $background-white !important;
          }
        }
      }
    }
    .agent-referral {
      padding: 16px;
      &-section {
        background-color: $background-default;
        padding: 8px;
        margin-top: 24px;
        button {
          background-color: $gradient-afternoon !important;
          font-weight: 400;
          border-radius: 25px;
          &:active {
            box-shadow: none;
          }
        }
      }
      &-text {
        color: $primary-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
      }
      &-code {
        .referral-link {
          font-size: 24px;
          color: $gradient-afternoon;
        }
        .referral-code {
          padding-left: 8px;
        }
        @include respond-to(md) {
          flex-direction: column;
          align-items: center;
          padding: 16px;
          button {
            margin-top: 20px;
          }
        }
        &.success {
          border: 1px solid $success-main;
        }
        .success-block {
          color: $success-main;
          svg {
            font-size: 24px;
          }
          .success-text {
            font-size: 14px;
            font-weight: 500;
          }
          @include respond-to(md) {
            flex-direction: column;
            align-items: center;
          }
          .flex-start {
            margin-top: 10px;
          }
        }
      }
      &-email {
        padding: 16px 8px;
        input[type='email'] {
          padding: 12px;
          height: 48px;
          width: 300px;
          border-radius: 4px;
          border-color: $border-light;
          outline: none;
          box-shadow: none;
          overflow: hidden;
          offset: none;
          border-width: 1px;
          border-style: solid;
          &.invalid {
            border-color: $error-500;
          }
          @include respond-to(md) {
            width: 280px;
            margin: 0 auto;
          }
        }
        .email-label {
          color: $integrity-navy;
          font-weight: 600;
          font-size: 16px;
          text-align: left;
          padding-left: 5px;
          margin-bottom: 4px;
        }
        .error {
          text-align: left;
          padding-left: 5px;
        }
        @include respond-to(md) {
          .flex-between {
            flex-direction: column;
            button {
              margin-top: 20px;
            }
          }
          .error {
            text-align: center;
          }
        }
      }
      &-content {
        padding: 24px 16px;
        &-list-item {
          margin-bottom: 24px;
          p {
            margin-bottom: 0 !important;
          }
          svg {
            font-size: 32px;
            color: $gradient-afternoon;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
