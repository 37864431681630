.collapsible-card {
  width: 100%;
}

.collapsible-card-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.collapsible-card-content {
  background: $background-white;
  border-radius: 8px;
  padding: 16px;
  height: auto;
}