.kpi-grid-container {
  display: grid;
  padding: 8px 0;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 24px;
  margin-top: 0.5rem !important;
  margin-bottom: 2rem;
}

@include respond-to(sm) {
  .kpi-grid-container {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.kpi-value {
  border-left: 16px solid $gradient-afternoon;
  background-color: $background-white;
  height: auto;
  border-radius: $border-radius;
  min-width: 250px;
  font-size: 48px;
  @include respond-to(lg){
    font-size: 32px;
  }
  &:hover {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  }
}
