.sorting {
  &__desktopmenu {
    display: block;
    position: absolute;
    top: 10px;
    left: -18.5rem;
    padding: 13px 25px;
    background: $light-blue;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
    transition: bottom 0.3s ease-in-out;
    z-index: 9;
    border-radius: 8px;
    max-height: 40vh;
    min-width: 18.6rem;
    overflow: auto;

    @include respond-to(sm) {
      display: none;
    }
  }

  &__menu {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 13px 25px;
    background: $light-blue;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
    transition: bottom 0.3s ease-in-out;
    z-index: 10;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-height: 60vh;
    overflow: auto;

    @include respond-to(sm) {
      display: block;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;
      text-align: left;
      color: $header-font;

      &--icon {
        color: $primary-action;
        font-size: 24px;
        cursor: pointer;
      }
    }

    &__content {
      background-color: $background-white;
      padding: 14px;
      border-radius: 4px;
    }
  }
}

.sorticon {
  color: $primary-action;
  cursor: pointer;
}

.sortInactiveIcon {
  color: $button-disabled-color;
  cursor: pointer;
}
