@for $i from 1 through 5 {
  .left-before-#{$i}::before {
    left: 6% * ($i - 1);
    width: 100% - 5.5% * ($i - 1);
  }

  .left-after-#{$i}::after {
    left: 6% * ($i - 1) !important;
    width: 100% - 5.5% * ($i - 1) !important;
  }
}

.multistep-form {
  margin-left: 35px;

  @include respond-to(md) {
    margin-left: 0;
  }

  &__desktop {
    display: flex;
    @extend .primary-font;

    &__steps {
      display: flex;
      justify-content: space-between;

      &__step {
        margin-left: 15px;
        position: relative;
        transition: 0.4s ease;

        &:first-child {
          margin-left: 0;
        }

        .circle {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: $row-highlight;
          @extend .flex-center;

          &.activeStep {
            background-color: $background-pill;
            color: $primary-action;
          }
        }

        span {
          display: block;
          width: 157px;
        }

        &:last-child {

          &:before,
          &:after {
            height: 0;
            width: 0;
          }
        }

        &:before {
          content: "";
          position: absolute;
          background: $row-highlight;
          height: 4px;
          top: 25%;
          transform: translateY(-50%);
          border-radius: 10px;
        }

        &:after {
          content: "";
          position: absolute;
          background: $primary-action;
          height: 4px;
          width: 0;
          top: 25%;
          transform: translateY(-50%);
          border-radius: 10px;
          left: 35%;
        }
      }
    }

    @include respond-to(md) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include respond-to(md) {
      display: block;
      margin-right: 10px;
      max-width: 420px;

      &__steps {
        display: flex;
        justify-content: space-between;

        &__step {
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          .progress-bar {
            height: 4px;
            border-radius: 10px;
            background-color: $row-highlight;
          }
        }
      }
    }
  }
}

.completed-progress-bar {
  &:after {
    content: "";
    position: absolute;
    background: $primary-action;
    height: 4px;
    width: 70%;
    top: 25%;
    transform: translateY(-50%);
    border-radius: 10px;
    left: 35%;
  }
}

.realtime-steps,
.custom-ad-steps {
  margin: 1.5rem 0 1.5rem 0 !important;

  .multistep-form {
    flex: 0 0 70%;
    max-width: 70%;
    position: relative;
    width: 100%;
    padding: 0;

    &__desktop {
      width: 100%;

      &__steps {
        width: 100%;

        &__step {
          width: 100%;

          span {
            letter-spacing: 0;
            margin-left: -2rem;
          }

          &:before {
            top: 1.5rem;

            @include respond-to(xxl) {
              width: 75%;
              left: 25%;
            }
          }

          &:last-child {
            width: 5%;
          }
        }
      }
    }

    +div {
      width: 20%;
      display: flex;
      justify-content: end;
    }
  }

  .desktop-buttons {
    .new-btn {
      min-width: 70px;
      max-width: 155px;
    }
  }

  .completed-progress-bar {
    &:after {
      top: 1.5rem;

      @include respond-to(xxl) {
        width: 75%;
        left: 25%;
      }
    }
  }

  @include respond-to(largeres) {
    flex-direction: column;

    .multistep-form {
      flex: 0 0 90%;
      max-width: 90%;

      +div {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}

.custom-ad-steps {
  .completed-progress-bar {
    .circle {
      cursor: pointer;

      +span {
        cursor: pointer;
      }
    }
  }
}