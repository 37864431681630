// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap');
@import 'variables';
@import 'mixin';

.custom-scroll-bar {
  overflow-y: scroll;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: $border-lines;
  border-radius: 5px;
  height: 20px;
}

.icon-button {
  cursor: pointer;
  color: $primary-main;
}

.Cancel-order {
  background-color: blank;
}

.search-bar-container {
  // width: 320px;
  width: 100%;
  /* margin: 0 auto;*/
  height: 52px;
  position: relative;
}

.search-bar {
  background: transparent;
  border: 1px solid #f6f6f6;
  font-size: 15px;
  padding-left: 40px;
  color: #4e5860;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 80%;
  border-radius: 50px;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.search-bar-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  font-size: 22px;
  color: #ac0a0ad1;
}

.line-height-1 {
  line-height: 1;
}

.search-result {
  position: absolute;
  top: 42px;
  width: 98%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.28);
  z-index: 1;
  background-color: #ffff;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item.active,
.list-group-item:hover {
  background-color: #e1e3e5;
  border-color: #d3d5d7;
}

.Leadgroup-card {
  border: 2px solid #ced2d8;
  border-collapse: separate;
  border-radius: 1.25rem;
}

.c-app {
  overflow: hidden;
}

@media screen and (max-width: 478px) {
  .c-header img {
    height: 25px;
  }
}

@media screen and (max-width: 375px) {
  .c-header img {
    height: 22px;
  }
}

.light-gray {
  color: $color-title;
}

.sc {
  font-size: 0.75rem;
}

.height-50 {
  max-height: 50px;
}

.margin-15 {
  margin: 0 -15px;
}

.btn-blue {
  color: $primary-action;
}

.cursor-pointer {
  cursor: pointer;
}

.font-blue {
  color: $header-font;
}

.fs-22 {
  font-size: 22px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: $fs-16;
}

.mb-20 {
  margin-bottom: 20px;
}

.font-075 {
  font-size: 0.75rem;
}

.br-4 {
  border-radius: 4px;
}

.cblack {
  color: $secondary-font;
}

.fs-24 {
  font-size: 2rem;
}

.modal-paymentMethod-dialog {
  top: 15%;
  max-width: 750px !important;
  overflow: auto;
}

.modal-paymentMethod-content {
  width: auto;
  height: auto;
}

.divMapSection {
  margin-left: '5vh';
  height: auto;
  width: 100%;
  border: '1px solid black';
}

.marginRight-6v {
  margin-right: '6vh';
}

.hr-line-order {
  border-width: 1px;
  border-color: #8c8b8b;
  margin-top: 0rem;
}

.div-border-rev-order {
  display: inline-block;
  border: '1px solid #8c8b8b';
}

table {
  border-collapse: separate;
  border: solid black 1px;
  border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
}

.fa-times-circle:hover {
  color: red;
  cursor: pointer;
}

.row-bordered {
  display: block;
  margin: 0;
}

.border-W1 {
  border: 1px solid rgb(148, 145, 145);
}

.mr--5 {
  margin-right: -1.4vh;
}

.parentEnable {
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
  visibility: hidden;
}

.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #fdfdfd;
  opacity: 0.8;
  z-index: 1000;
}

.input-disabled {
  background-color: #fdfdfd !important;
  cursor: not-allowed;
}

.input-NotValid {
  background-color: #fdfdfd !important;
  border: 2px solid red;
}

.bg-light {
  background-color: $background-modal !important;
}

.c-sidebar,
.c-sidebar-brand,
.c-sidebar-minimizer {
  background-color: $background-white !important;
}

.c-header .c-header-toggler-icon {
  background-image: url('../assets/Images/bars.png') !important;
  background-size: inherit;
}

body {
  background-color: $background-modal !important;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body h6 {
  cursor: pointer;
}

body .listheader h5 {
  word-wrap: normal;
  font-size: 14px;
  font-weight: 700;
  color: #474748;
  /*text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/
  width: 100%;
}

/*
.cardTitle, .card-body{
    font-family: Open Sans, Helvetica Neue, sans-serif;

}*/

.fa,
.fas,
.far,
.fal {
  color: #2370a4;
}

.quickSearch-icon {
  font-size: 20px;
  /*height: 38px;
    width: 45px;
    text-align: center;
    padding: 0px;
    margin: 0px;*/
  margin-left: -30px;
  /* padding-left: 3px;
    padding-top: 2px;
    background-color: $background-white;
    border-radius: 0 .25rem 0.25rem 0;
    border-color: #C1C1C2 !important;
    border-width: 2px 2px 2px 0px;
    border-style: solid;*/
  z-index: 1;
}

/*  .form-control:focus {
  .quickSearch-icon {
          /box-shadow: 0.2rem 0.2rem 0.2rem 0px rgb(0 123 255/25%);
  }
}*/
/*
.form-control{
  font-size: 0.975rem !important;
  border:1px solid #B1B2B6;

  height: 38px;
  letter-spacing: 1px;
  padding-right:35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  }*/

.form-control:focus::placeholder {
  color: transparent;
}

.form-control-orderleads {
  //font-size: 0.875rem !important;
  border: 1px solid #b1b2b6;
  padding: 3px;
  height: 26px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}

.shoppingCartqty {
  border: 1px solid #b1b2b6;
  padding: 3px;
  height: 26px;
  text-align: right;
  font-size: 16px;
  max-width: 70px;
  width: 65px;
}

.form-control-shoppingCart {
  font-size: 0.875rem !important;
  border: 1px solid #b1b2b6;
  height: 30px;
  width: 100% !important;
}

.form-control {
  border-color: $border-lines;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #a7a8a93b;
  opacity: 1;
}

.form-control:focus {
  border-color: rgba(29, 66, 137, 0.8);
  border-width: 2px;
  /*box-shadow: 0 0 0 0.2rem rgba(29, 66, 137, 0.20);*/
  box-shadow: none;
}

.shopping-cart-input {
  max-width: 4rem;
  min-width: 4rem;
  margin: auto;
  display: block;
  height: 1.8rem;
  text-align: right;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.bold {
  font-weight: 700;
}

.textcenter {
  text-align: center;
}

.user-menu {
  font-family: 'Lato', sans-serif;
}

.card {
  letter-spacing: 1px;
}

.cardTitle {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0px;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  color: $secondary-font;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cardTitle + a i {
  font-size: 1.125rem;
  color: #2371a4;
}

.receivedDateFilterText {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 800;
  color: $secondary-font;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.receivedDateFilterIcon {
  color: $secondary-font;
  padding: 0.3rem;
  padding-left: 0;
}

/*
  .card-body{
    padding-bottom:0px;
  }
  */

.cardLabel {
  /* width: 50%;*/
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  color: #4b4b4c;
  margin: 0px;
  padding-bottom: 2px;
  word-wrap: normal;
}

.stateIdCls {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}

.stateIdCls:hover {
  overflow: visible;
  white-space: normal;
}

/*
  .cardValue{
    word-wrap: normal;
  }
  */
.borderedCard {
  border: 2px solid #386a92;
}

.form-check-input {
  width: 15px;
  height: 15px;
  zoom: 1.2;
  margin-top: 3px;
}

.list-input {
  width: 15px;
  height: 15px;
}

.card {
  border-radius: 0.45rem;
}

.card-header:first-child {
  display: flex;
  flex-direction: row;
  border-radius: 0.45rem 0.45rem 0 0;
  /* padding-right:20px;*/
}

.tableHeaderWrapper {
  height: 32px;
  width: 100%;
}

/**/
.formDiv {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.formDivSearch {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.formDiv .rbt {
  width: 100%;
}

.formDivSearch .rbt {
  width: 100%;
}

.formDiv input {
  padding-right: 3rem !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.formDivSearch input {
  padding-right: 3rem !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// .cardRightspace {
//   /*padding-right:15px;*/
//   padding-right: 0.938rem;
// }

// .cardBottomspace {
//   margin-bottom: 0.938rem !important;
// }

.filterSearch {
  padding: 1rem 0;
  border: 1px solid #dcdcdc;
  border-width: 1px 0;
}

.filterSearch i {
  font-weight: 100;
}

.tableHeader {
  height: 30px;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 40px;
}

.tableLeft {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  i:first-child {
    padding-left: 0px;
  }

  i {
    padding-left: 1.5rem;
    font-weight: 100;
  }

  /* div {
      margin-left:1.5rem;
      margin-right:0.9rem;
    }*/
}

.icon-active {
  color: #7ecc34;
}

.tableLeft .icon-active {
  color: #7ecc34;
  text-shadow: 1px 1px 1px #f1f1f1;
  font-weight: 400;
}

.tableRight {
  display: flex;
  width: 20%;
  align-items: center;
  padding: 0% 0% 1%;
  justify-content: flex-end;

  i {
    padding: 0% 5%;
    font-weight: 100;
  }

  .iconLabel {
    font-size: 11px;
    font-weight: 500;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .borderRight {
    border-right: 2px solid #dfe3e6;
    height: 40px;
    width: 0%;
  }
}

.iconLabel {
  font-size: 11px;
  font-weight: 500;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (min-width: 768px) {
  .fixedWidthButton {
    width: 200px !important;
  }

  .fixedWidthButton-270 {
    width: 270px !important;
  }
}

.hLine {
  border-color: #2370a4;
}

.borderRight,
.borderRightRealtime {
  border-right: 2px solid #dfe3e6;
  height: 40px;
  width: 0%;
}

@media only screen and (max-width: 575px) {
  .borderRight {
    border-right: 2px solid #dfe3e600;
    padding-bottom: 1rem;
  }
}

.mobileview__toggle {
  display: none;
}

@media only screen and (max-width: 601px) {
  .borderRightRealtime {
    border-right: 2px solid #dfe3e600;
    padding-bottom: 1rem;
  }

  .webview__toggle {
    display: none !important;
  }

  .mobileview__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $background-white;
    background-color: $background-header-nav;
    padding: 18px 0px;
    padding-right: 1.5rem;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  #grid {
    display: none;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  #grid {
    display: none;
  }

  i:nth-child(2) {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 428px) and (orientation: portrait) {
  .tableRight #grid {
    display: none;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .tableRight #grid {
    display: none;
  }
}

.expandIconView {
  margin-left: -5px;
}

.assignLeads_select .css-2b097c-container {
  width: 100%;
}

.c-sidebar-nav {
  font-size: 17px;
  letter-spacing: 0px;
  order: 2;
}

@media (min-width: 992px) {
  .c-sidebar-minimized .c-sidebar-minimizer {
    position: static;
  }
}

.listcard {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  /* margin-bottom: 1.5rem; */
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #d8dbe0;
  border-top: 0;
  /*border-radius: 0.25rem;*/
  background-color: $background-white;
  height: auto;
  padding: 10px 0px 10px 0;
}

.listcard:last-child {
  border-radius: 0px 0px 0.25rem 0.25rem;
}

.listcard p {
  word-wrap: normal;
}

.listdiv {
  /* padding: 2%;
     width: "100%"*/
  margin-left: -12px;
}

.listheader {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  /* margin-bottom: 1.5rem; */
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem 0.25rem 0px 0px;
  background-color: #e6ecf1;
  height: 50px;
  justify-content: center;
}

.table {
  margin: 0px;
  padding: 0px;
  border-spacing: 0px;
  border: 1px solid #d8dbe0;
}

.table thead th {
  border: none;
  border-bottom: 1px solid #ccc !important;
}

.table thead th h5 {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.table .thead-light th {
  border: 0px;
  height: 50px;
  vertical-align: middle;
  background-color: #d2d7da;
  color: $secondary-font;
}

.table .thead-light th:first-child {
  border-top-left-radius: 0.4rem;
}

.table .thead-light th:last-child {
  border-top-right-radius: 0.4rem;
}

.table tbody:last-child tr td:first-child {
  border-bottom-left-radius: 0.4rem !important;
}

.table tbody:last-child tr td:last-child {
  border-bottom-right-radius: 0.4rem !important;
}

@media only screen and (max-width: 1409px) {
  .table .thead-light th {
    vertical-align: top;
  }
}

/*
.table tbody:last-child tr td{

  border-bottom:none;
}*/
.table tbody {
  border: 1px solid #d8dbe0;
  border-top: 0;
  /*border-radius: 0.25rem;*/
  background-color: $background-white;
}

.table tbody td {
  border: none;
  border-bottom: 1px solid #cccccc;
  border-radius: 0px !important;
}

.table-outline td {
  vertical-align: top;
}

.table-hover tbody tr:hover {
  /* color: #3c4b64;
  background-color: rgba(0, 0, 21, 0.075);*/
  border: 2px solid #386a92;
  background: transparent;
}

.tableInner {
  width: 100%;
  border: none;
  border-spacing: 0px;
}

.tableInner td {
  border: none;
}

.tableInner tr:hover {
  background-color: transparent;
}

.assetsHeader th {
  background: rgb(31, 49, 95);
  color: $background-white;
}

.assetsHeader th:first-child {
  border-top-left-radius: 0.4rem;
}

.assetsHeader th:last-child {
  border-top-right-radius: 0.4rem;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  align-items: center;
}

/*
  #manageLeadPagination{
    display:flex;
    flex: 0 0 100%;
    max-width: 100%;
     justify-content: space-between;
  }
*/
.pagination li {
  padding-right: 10px;
}

.pagination li a {
  display: inline-block;
  text-align: center;

  line-height: 25px;

  padding: 0px 8px;
  text-decoration: none;
  /*width: 35px;
    height: 35px;*/
  color: #4e7c97;
  /*font-weight: bold;*/
  letter-spacing: 0px;
  white-space: nowrap;
}

.pagination li a:hover {
  color: #4e7c97;
  border-radius: 10%;
  background-color: #cccccc;
}

.pagination__link--active a {
  /*border: 2px solid #4E7C97;*/
  color: $background-white !important;
  border-radius: 10%;
  background-color: #465863;
  font-weight: bold;
}

.previous {
  .pagination__link {
    border-right: 2px solid #dfe3e6;
    font-size: 24px;
    font-weight: 800;
    padding-right: 20px;
  }

  a:hover {
    background-color: transparent !important;
  }
}

.next {
  .pagination__link {
    border-left: 2px solid #dfe3e6;
    font-size: 24px;
    font-weight: 800;
    padding-left: 16px;
  }

  a:hover {
    background-color: transparent !important;
  }
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202) !important;
}

.pagination li:first-child {
  padding-right: 20px;
}

.pagination li:last-child {
  padding-left: 20px;
}

.paginationWrapper {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
}

.paginationWrapper .recordsperpage {
  padding-right: 0px;
  padding-top: 5px;
  padding-left: 20px;
  white-space: nowrap;
  position: absolute;
  right: 30px;
  text-align: right;
}

.paginationWrapper .recordsperpage select {
  height: 36px;
  padding: 7px 12px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  outline: 0;
}

/*
.paginationWrapper .recordsperpage select option:checked,
.paginationWrapper .recordsperpage select option:hover {
    box-shadow: 0 0 10px 100px #000 inset;

    background-color:blue;
}*/

.paginationWrapper .recordsperpage select:focus > option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
  background-color: blue;
}

.paginationWrapper .recordsperpage div {
  display: inline;
}

button.close span:first-child {
  font-size: 30px;
  font-weight: 200;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-footer {
  justify-content: flex-start;
  border-top: 0px;
}

.modal-noteReminder-dialog {
  max-width: 750px;
  top: 20%;
}

@media (max-width: '1280px') {
  .modal-disclaimer-content {
    width: auto;
  }
}

.note-modal-body .date-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  input {
    min-width: 300px;
  }

  p {
    margin: 0 0 0 10px;
  }
}

.custom-note-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0 5px;
}

.modal-noteReminder-content .modal-header {
  border-color: transparent;
  padding: 1.5rem 1rem 0;

  #addNoteReminder {
    font-size: 1.65rem;
  }

  label {
    font-size: 0.895rem;
    margin: 0 15px;

    input {
      margin-right: 3px;
      top: 1px;
      position: relative;
    }
  }
}

.btn-primary:active {
  background: #0b4164;
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

.btn-primary:focus {
  box-shadow: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  //box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.20);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

.btn-outline-primary {
  width: 100%;
  max-width: 100%;
  border: 1px solid $primary-action;
  outline: 0 !important;
  background: $background-white;
  color: $primary-action;
  padding: 0.4rem 1rem;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 3px;
  text-transform: capitalize;
}

.btn-outline-primary:hover {
  color: $primary-action;
  background-color: #fff;
  border: 1px solid $primary-action;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: $primary-action;
  background-color: #fff;
  border: 1px solid $primary-action;
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-outline-primary:disabled {
  opacity: 0.65;
  color: $primary-action;
  cursor: not-allowed !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

/*
.btn-outline-primary:hover {
  color: #2371A4;
  background-color: transparent;
  border: 1px solid #2371A4;
}
*/

.btn-primary:disabled {
  opacity: 0.65;
  background-color: #a0c4f8 !important;
  cursor: not-allowed !important;
}

.btn-outline-secondary {
  padding: 0.4rem 1rem;
}

.btn-primary.btn-warn:active {
  background: $warning-500 !important;
  box-shadow: 0 0 0 0.2rem rgba($warning-bg, 0.2);
  color: $primary-font !important;
  outline: none !important;
}

.btn-link {
  font-weight: 400;
  color: #2371a4 !important;
  text-decoration: none;
  text-underline-offset: 2px;
  padding: 0px;
}

.btn-link:disabled {
  color: #a7a8a9 !important;
  font-weight: 400;
}

.couponBtnLink {
  font-weight: 600;
}

.couponCodeInput {
  width: 170px;
}

/*
.btn-primary:disabled:hover, {

 background: #c2c2c2;
  color:#808080;
  border:1px solid #676A6D !important
}*/

.swal2-styled.swal2-confirm {
  border: 0;
  background-color: #2371a4 !important;
  color: #fff;
  outline: 0 !important;
  border-radius: 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 10px 30px;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

.swal2-styled.swal2-cancel {
  outline: 0 !important;
  background: transparent;
  color: #2371a4;
  border-radius: 1.5rem;
  border: 1px solid #2371a4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
  background: transparent;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: none;
}

.dropdown-item:active {
  text-decoration: none !important;
  color: $primary-action;
  background-color: $background-body;
}

.dropdown-item:hover {
  text-decoration: none !important;
  background: $background-body;
  color: $primary-action;
}

.c-header .dropdown-menu {
  padding-bottom: 0;

  .dropdown-item {
    border-bottom: 1px solid $border-lines;
  }
}

.submenu-icon {
  margin-right: 10px;
  font-size: 1rem;
  color: $primary-action;
}

.carddiv {
  // border-top: 1px solid gainsboro;
  border: 1px solid #ced2d8;
  border-collapse: separate;
  border-radius: 1.25rem;
  background-color: white;
  padding: 3%;
}

.carddivSize {
  height: 600px;
  max-height: 100%;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 1.3rem;
  overflow-x: hidden !important;
}

.card-deck .card {
  -webkit-flex: 1 0;
  flex: 1 0;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}

@media screen and (max-width: 576px) {
  .card-deck .card {
    margin-bottom: 1.5rem !important;
  }
}

.cardContent {
  padding: 0% 4%;
}

#editicon {
  display: flex;
  justify-content: flex-end;
  padding-right: 4%;
  height: 1.5rem;
}

#viewInput {
  input {
    height: 2rem;
    padding: 0.375rem 0.75rem;
    width: 100%;
    // color: #808080;
    color: #3b3b3b;
    border: 1px solid #a7a8a92b;
    // background:transparent;
    background-color: #f2f2f2;
    border-radius: 0.25rem;
  }

  .inputDropdown {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .checkboxDiv {
    position: relative;
    display: block;
  }

  .inputCheckbox {
    width: 18px;
    //  height:18px;
  }

  textarea {
    width: 100%;
    border-radius: 0.25rem;
  }
}

.css-g1d714-ValueContainer {
  max-height: 30px;
}

#inputDiv {
  .multiselectdd {
    width: 100%;
  }

  input {
    width: 100%;
    height: 2rem;
    padding: 0.375rem 0.75rem 0.375rem 0.55rem;
    color: #808080;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 0.25rem;
  }

  input[type='text']:focus,
  input[type='text']:focus-visible {
    border-color: rgba(29, 66, 137, 0.8);
    border-width: 2px;
    /*box-shadow: 0 0 0 0.2rem rgba(29, 66, 137, 0.20);*/
    box-shadow: none;
  }

  .input[type='text']:not(:focus):focus-visible {
    border-color: rgba(29, 66, 137, 0.8);
    border-width: 2px;
    /*box-shadow: 0 0 0 0.2rem rgba(29, 66, 137, 0.20);*/
    box-shadow: none;
  }

  .btn:focus:not(:focus-visible) {
    box-shadow: none;
  }

  .css-b8ldur-Input input:focus {
    box-shadow: none;
  }

  .css-g1d714-ValueContainer {
    position: static;
  }

  .inputDropdown {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .checkboxDiv {
    position: relative;
    display: block;
  }

  .inputCheckbox {
    width: 18px;
  }

  textarea {
    width: 100%;
    border-radius: 0.25rem;
  }

  .input-error {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.05);
  }
}

/*
.btn-Add-Cart{
 width:auto;
 font-size: 14px;
  font-weight: 600;
  letter-spacing: .5px;
}
*/
/*shopping cart table styles start */

.table-shoppingcart {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  border: 1px solid #b1b2b6;
  border-radius: 0.4rem;
  border-spacing: 0px;
  background: $background-white;
  margin-top: 1.5rem;
}

.table-shoppingcart td,
.table-shoppingcart th {
  border: none;
  padding: 10px;
  vertical-align: top !important;
  text-align: left;
}

.table-shoppingcart th {
  border: none;
  border-bottom: 1px solid #ccc !important;
}

.table-shoppingcart tbody {
  background: none;
}

.table-shoppingcart tr:last-child td:first-child {
  border-bottom-left-radius: 0.2rem;
}

.table-shoppingcart tr:last-child td:last-child {
  border-bottom-right-radius: 0.2rem;
}

@media screen and (max-width: 300px) {
  .table-shoppingcart {
    border: none;
  }

  .table-shoppingcart td,
  .table-shoppingcart th {
    border: none;
  }

  .table-mo-shoppingcart tbody tr {
    margin-bottom: 10px;
  }

  .table-mo-shoppingcart td:before {
    content: attr(title);
    display: block;
    font-weight: bold;
    margin: 0 0 2px;
    color: #000;
    width: 100% !important;
  }

  .table-mo-shoppingcart td:before {
    width: 100%;
  }
}

@media screen and (max-width: 601px) {
  .table-shoppingcart tr.highlight-reminder {
    background-color: transparent;
  }

  .table-shoppingcart {
    border: none;
  }

  .table-shoppingcart td,
  .table-shoppingcart th {
    border: none;
  }

  .table-mo-shoppingcart {
    word-wrap: break-word;
  }

  .table-mo-shoppingcart thead {
    position: absolute;
    left: -999em;
    top: -999em;
  }

  .table-mo-shoppingcart td {
    display: block;
    float: left;
    width: 100%;
    clear: both;
    /*background:#f5f5f5;*/
    padding: 10px 15px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0px;
    border-radius: 0.4rem;
    text-align: left !important;
    justify-content: flex-start !important;
  }

  .table-mo-shoppingcart tbody td:first-child {
    padding-top: 50px;
  }

  .table-mo-shoppingcart tbody,
  .table-mo-shoppingcart tr {
    display: block;
    border: 0px;
    background: $background-white;
  }

  .table-mo-shoppingcart td:before {
    content: attr(title);
    display: inline-table;
    font-weight: bold;
    margin: 0 0 2px;
    color: #000;
    width: 40%;
  }

  .table-mo-shoppingcart td div {
    display: inline;
  }

  .table-mo-shoppingcart tbody tr {
    float: left;
    width: 100%;
    margin: 0px;
    border: 1px solid #b1b2b6;
    border-radius: 0.8rem;
    border-spacing: 0px;
    margin-bottom: 20px;
  }

  .table-mo-shoppingcart tbody {
    background: none;
  }

  .table-shoppingcart {
    background: none;
  }

  .table-mo-shoppingcart tbody .action {
    position: absolute;
    right: 40px;
    padding-top: 20px !important;
    width: 10%;
    white-space: nowrap;
    background: transparent;
  }

  .table-mo-shoppingcart .input-mo-view {
    width: 25%;
  }
}

.shoppingcart-amt-wrapper {
  padding: 0;
  display: flex;
  /* flex-wrap: nowrap; */

  align-items: flex-end;
}

.coupon-row {
  display: flex;
  // justify-content: flex-end;
  // padding: 0.5em;
  flex-wrap: nowrap;
}

.coupon-row > label {
  text-align: right;
  /*padding: .5em 1em .5em 0;*/
  white-space: nowrap;
}

.coupon-row > .coupon-input {
  width: 200px;
  height: 30px;
}

.amt-row {
  display: flex;
  // padding-bottom: 0.25rem;
  flex-wrap: wrap;
  // margin-left: auto;
}

.amt-row > label {
  /* padding: .5rem 1rem;*/
  flex: 3;
  text-align: right;
}

.amt-row > .val {
  flex: 1;
  text-align: right;
  /*padding: .5rem;*/
}

@media screen and (max-width: 500px) {
  .form-row label,
  .form-row div {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .shoppingcart-amt-wrapper .form-row label {
    max-width: 25%;
    text-align: lect;
  }

  .coupon-row > label {
    text-align: right;
  }

  .coupon-row > .coupon-input {
    width: 100%;
  }

  .amt-row {
    justify-content: flex-start;
  }

  .amt-row > label {
    flex: auto;
    text-align: left;
  }

  .amt-row > .val {
    text-align: right;
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 768px) {
  .amt-row > .val {
    flex: 1;
    text-align: right;
  }

  .amt-row {
    padding: 0px;
  }
}

@media screen and (min-width: 992px) {
  .amt-row > .val {
    flex: 1;
    text-align: right;
  }
}

@media screen and (min-width: 1200px) {
  .amt-row > .val {
    flex: 1;
    text-align: right;
  }
}

/* shopping cart styles end*/

.edit-savebtn button,
.edit-atnbtn div {
  width: 150px;
  cursor: pointer;
}

.campaign button {
  margin-right: 0.5rem;
}

@media screen and (max-width: 1800px) {
  .campaign button {
    width: 120px;
  }
}

@media screen and (max-width: 576px) {
  .edit-savebtn {
    width: 100%;
    /* max-width: 100%; */
    /* justify-content: left; */
    text-align: left !important;
    margin-right: 0px !important;
    margin-bottom: 1rem;

    .delete-campaign {
      margin: 25px 5px 20px 0;
    }
  }

  .edit-atnbtn {
    width: 100%;
    /* max-width: 100%; */
    /* justify-content: left; */
    text-align: left !important;
    margin-right: 0px !important;
  }

  .edit-savebtn button {
    max-width: 100% !important;
    width: 100% !important;
    margin-right: 0px !important;
    /*margin-bottom:1.25rem;*/
  }

  .edit-atnbtn div {
    max-width: 100% !important;
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 576px) {
  #viewInput {
    .checkboxDiv {
      width: 100%;
    }

    .inputCheckbox {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  #inputDiv {
    .inputDropdown {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    .checkboxDiv {
      width: 100%;
    }

    .inputCheckbox {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .pagination li:first-child {
    padding-right: 10px;
  }

  .pagination li:last-child {
    padding-left: 10px;
  }

  .pagination li {
    padding-right: 4px;
  }

  /*
 #manageLeadPagination{
    padding-right: 45px;
 }

*/
}

@media only screen and (max-width: 767px) {
  .tableHeaderWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .logoLeftSpace-md {
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  .formDiv .rbt {
    width: 100%;
  }
}

@media (max-width: 600px) {
  /* .formDiv{
      width: 100% !important;
    }*/
  .formDivSearch {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  .formDiv {
    width: 100%;
  }

  .formDivSearch {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .formDiv {
    width: 100%;
  }

  .formDivSearch {
    width: 100%;
  }
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: $primary-font;
}

.tooltip-inner {
  display: block;
  max-width: 270px;
  padding: 1rem;
  color: $background-white;
  background-color: $primary-font;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 200;
  text-align: left;
  padding-bottom: 1rem;
  line-height: 18px;
}

.tooltip-inner .unitCost {
  padding-top: 0.75rem;
  font-weight: 500;
}

.tooltip-inner svg {
  float: right;
}

#actionsId .dropdown {
  position: relative;
  display: inline-block;
}

#actionsId ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 45px;
  right: 0px;
  width: 305px;
  background-color: white;
  font-weight: bold;
  position: absolute;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #a7a8a944;
  border-radius: 0.4rem;
  z-index: 1;
}

#actionsId li a,
#actionsId li .no-credit {
  color: #000;
  text-decoration: none;
  font-weight: normal;
}

#actionsId li .no-credit {
  color: #7f7f8a;
  font-style: italic;
}

#actionsId li {
  text-align: left;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;

  &.no-credit-li {
    cursor: not-allowed !important;
  }
}

#actionsId li:last-child {
  border-bottom: none;
}

#actionsId li:hover {
  background-color: #e5e5e5;
  color: white;
}

#actionsId .button {
  background-color: #2371a4;
  padding: 0.5rem 1rem;
  color: white;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
}

.edit-atnbtn .disabled div {
  cursor: not-allowed !important;
}

.edit-atnbtn .disabled {
  opacity: 0.65;
  background-color: #a7a8a9 !important;
  cursor: not-allowed !important;
}

#actionsId .button:active {
  box-shadow: 0 0 0 0.2rem rgba(31, 49, 95, 0.2);
}

#actionsId .button:before {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  height: 0.65em;
  /* left: 0.15em; */
  position: absolute;
  right: 13px;
  top: 12px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.65em;
}

.colorBlack {
  color: $secondary-font;
}

.colorWhite {
  color: $background-white;
}

.dropdown {
  position: relative;
  // display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 12.8rem;
  overflow: auto;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0.9rem;
  left: -12.5rem;
  padding: 0.3rem 0.3rem 0.2rem 0.3rem;
  text-align: left;
}

.hideElement {
  display: none;
}

.disableAlertIcon {
  opacity: 0.6;
}

.enableAlertIcon {
  opacity: 1;
}

.showElement {
  display: inline;
}

.leadsgroupTextsize {
  font-size: 16px;
  font-weight: 800;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.leadsgroupTextsize2 {
  font-size: 16px;
}

.leadtypeOuterdiv h6 {
  line-height: 20px;
}

svg.infoCircle {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: Top;
  margin-top: 3px;
}

.leadtypeOuterdiv .overlay {
  color: '#000';
  cursor: pointer;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.col-form-label {
  font-weight: 500;
}

.iconSize-15 {
  font-size: 1.5rem;
}

.iconSize-12 {
  font-size: 1.25rem;
}

.iconSize-14 {
  font-size: 16px;
  cursor: pointer;
}

//for fontawesome tag
.iconSizewidth-24 {
  width: 1.25rem !important;
  cursor: pointer;
}

.iconWidth-25 {
  width: 2.5rem !important;
  cursor: pointer;
}

//for i tag
.iconSize-24 {
  font-size: 1.5rem !important;
  cursor: pointer;
}

.iconSize-25 {
  font-size: 2.5rem !important;
  cursor: pointer;
}

.disabledIcon {
  opacity: 0.6;
  cursor: none;
  pointer-events: not-allowed;
  color: #a7a8a9;
}

.iconSize-10 {
  font-size: 0.1rem;
  cursor: pointer;
}

.successIconSize {
  width: 100% !important;
  max-width: 65px;
  border-radius: 4px 0 0 4px;
  background-color: $success-200;

  svg {
    color: $primary-font;
  }
}

.disableIconPointer {
  pointer-events: none;
}

.div-editTitle {
  align-items: center;
}

.h1-editTitle {
  /*margin-Top: 10px;
  width: 50%;
  color: #676A6D;*/
  color: #202a44;
  font-weight: 100;
  white-space: nowrap;
  letter-spacing: 0px;
}

.h3-title {
  color: #202a44;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0px;
}

.titleIcon {
  color: #202a44;
  font-size: 1.75rem;
}

.bg-transparent {
  background: transparent;
}

.leaddiv {
  padding: 0% 0%;
}

.leadCard {
  margin-bottom: 10%;

  h1 {
    font-size: 16px;
    font-weight: 800;
    padding: 2% 4%;
  }
}

.h-60 {
  height: 60px;
}

.cards-dropdown {
  width: 100%;
}

.h-550 {
  height: 550px;
}

.hiddenRow {
  padding: 0;
  width: 30%;
}

.hiddenRow .textSize h5 {
  color: #768192;
}

.hiddenRow .textAddress {
  color: #3c4b64;
}

.hiddenRow .thead-light th {
  height: 30px !important;
  background-color: #d2d7da;
}

.td-bgColor {
  /*border-top: 1px dotted #d8dbe0 !important;*/
  background-color: #f8f9fa;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.table tbody tr:focus,
.table tbody tr:focus-visible {
  background-color: #f8f9fa;
  outline: 0;
  /*remove outline*/
}

.chekoutOrderbg-Border {
  background-color: $background-white;
  border: 1px solid #b1b2b6;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  margin-top: 1.5rem;
}

.checkoutOrderbg {
  border: 1px solid #b1b2b6;
  border-width: 1px 1px 1px 0px;
  background-color: #e6ecf1;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  margin-top: 1.5rem;
}

.chekoutOrderbg-Borderbottom {
  border-bottom: 1px solid #b1b2b6;
}

@media screen and (max-width: 576px) {
  .chekoutOrderbg-Border {
    border: 1px solid #b1b2b6;
    border-radius: 0.4rem;
  }

  .checkoutOrderbg {
    border: 1px solid #b1b2b6;
    background-color: #e6ecf1;
    border-radius: 0.4rem;
  }
}

.ordersummary-msg {
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0 4px 4px 0;
  color: $primary-font;
  display: flex;
  align-items: center;
  margin-left: 65px;
}

#haveCards td {
  border-radius: 0px !important;
}

#haveCards:last-child td {
  border: none;
}

#haveCards:last-child td:first-child {
  border-bottom-left-radius: 0.4rem !important;
}

#haveCards:last-child td:last-child {
  border-bottom-right-radius: 0.4rem !important;
}

.c-sidebar-nav-icon {
  height: 1.5rem !important;
  margin-right: -5px;
}

.c-sidebar-nav-link {
  font-size: 19px;
  font-weight: 400;
  padding: 1.4445rem 1rem;
  font-family: 'Lato', sans-serif;
}

.c-sidebar-nav-link .c-sidebar-nav-icon {
  &.inactive {
    display: block;
  }

  &.active {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .c-header-nav-link {
    line-height: 0;
    font-size: 0px;
    color: transparent;
  }

  .dropdown-toggle::after {
    display: inline;
    color: transparent;
    font-size: 20px;
  }

  .mobile-userIcon {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .c-avatar {
    display: none;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.width-30 {
  width: 30%;
}

.flex-item {
  padding-bottom: 20px;
}

hr.logoborder {
  width: 80%;
  border-color: #7584a6;
  margin: 0 auto;
  padding: 0px;
}

hr.sectionDivider {
  width: 100%;
  border-color: #cccccc;
  margin: 0 auto;
  padding: 0px;
}

@media only screen and (max-width: 387px) {
  hr.sectionDivider {
    margin: 0 auto;
    /* margin-top:1.85rem;*/
  }

  #cardViewDiv {
    margin-top: 2rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .c-sidebar-backdrop .c-show {
    opacity: 0.5;
  }

  .c-sidebar-backdrop .c-fade {
    opacity: 0;
  }

  .c-sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
  }

  .c-sidebar {
    .sidebar-cash,
    .sidebar-close {
      display: flex !important;
      justify-content: flex-end;
      padding: 1rem 1rem 1rem 0;
      font-size: 1rem;
    }

    .sidebar-close {
      padding: 1rem 1rem 0 0;
      font-size: 1.5rem;
    }
  }
}

#filterContent {
  margin-left: 0px;

  background-color: $light-blue !important;
  width: 256px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 1.3;
  z-index: 1032;
}

#filterContent p {
  height: 10px;
}

#filterContent .scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

#filterContent .scrollable::-webkit-scrollbar {
  height: 7px;
  width: 4px;
}

#filterContent .scrollable::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

#filterContent .css-yk16xz-control {
  border-color: #a7a8a9;
}

.c-sidebar .c-sidebar-nav-link:hover {
  color: $primary-font;
  background: $background-modal;
  text-decoration: none !important;
  // for expanded  title style
  /*.c-sidebar .c-sidebar-nav-link[title]:hover::after {
  content:attr(title);
  color:transparent;
  border:none;
}*/
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar {
  /* background: rgba(255, 255, 255, 0.15);*/
  background: $background-modal;
  color: $background-header-nav;
  text-decoration: none;
  border-left: outset;
  font-weight: 700;
  padding-left: 0;

  .sidebar-close,
  .sidebar-cash {
    display: none;
  }
}

.c-active .sidebarIcons {
  color: #1d4289;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  /* filter: brightness(0) saturate(100%) invert(19%) sepia(84%) saturate(1473%) hue-rotate(203deg) brightness(91%) contrast(93%);*/
  filter: brightness(0) saturate(100%) invert(11%) sepia(64%) saturate(608%)
    hue-rotate(184deg) brightness(89%) contrast(93%);
  color: #202a44;

  &.inactive {
    display: none;
  }

  &.active {
    display: block;
  }
}

.c-sidebar-nav-link .c-sidebar-nav-icon {
  color: rgba(67, 74, 81, 1) !important;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle {
  background: rgba(255, 255, 255, 0.15);
  width: 56px !important;
  color: transparent;

  .c-sidebar-nav-icon {
    filter: brightness(100%) saturate(100%) hue-rotate(365deg);
  }
}

// for expanded  title style
.c-sidebar .c-sidebar-nav-link[title]:hover::after {
  content: attr(title);
  color: transparent;
  border: none;
}

//for collapsed title style
.c-sidebar-minimized .c-sidebar-nav-link[title]:hover::after {
  content: attr(title);
  color: #000;
  border: #000;
}

@media only screen and (min-width: 992px) {
  .c-sidebar-minimized .c-sidebar-nav-link[datatitle]:hover:after {
    opacity: 0;
    visibility: visible !important;
  }

  .c-sidebar-minimized .c-sidebar-nav-link:hover {
    overflow: visible;
    color: transparent;
  }

  .c-sidebar-minimized .c-sidebar-nav-link {
    color: transparent !important;
  }

  .c-sidebar-minimized .c-sidebar-nav-link .c-active {
    color: transparent !important;
  }
}

.c-sidebar-nav-link[datatitle]:hover:after {
  visibility: hidden;
}

/*.c-sidebar-nav-item:hover  {
   .c-active .sidebarIcons {
      color:$background-white;
    }
  }*/
.c-sidebar-nav-link[datatitle]:after {
  content: attr(datatitle);
  position: absolute;
  bottom: 3rem;
  left: 70%;
  padding: 4px;
  border: 1px solid black;
  background-color: $background-white;
  color: #666;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  font-size: 12px;
  visibility: hidden;
}

.c-sidebar-nav-link[datatitle] {
  position: relative;
  color: rgba(67, 74, 81, 1);
}

/*
  .c-sidebar-nav-link:after{
    width:200px;
  }
*/

.css-114m9zj-menu {
  color: #cccccc !important;
}

.css-1gormuo-menu,
.css-2613qy-menu {
  color: #808080;
}

.filterBgColor {
  background-color: #a7a8a918;
}

.prev-nav,
.next-nav {
  font-size: 40px;
  cursor: pointer;
}

.prev-nav {
  margin-right: -10px;
}

.prev-nav-disabled,
.next-nav-disabled {
  font-size: 40px;
  color: #cccccc;
}

.prev-nav-disabled {
  margin-right: -10px;
}

.agentHeader-border {
  border-bottom: 1px solid #d8dbe0;
  margin-right: 0px;
}

.breakWord {
  word-wrap: break-word;
}

.stickBottomAlign {
  vertical-align: text-bottom;
}

.bread-crumb a,
.bread-crumb label {
  color: #101820cc !important;
  font-size: 14px;
  text-underline-offset: 2px;
}

.bread-crumb label:hover {
  color: #101820cc !important;
  text-decoration: underline;
}

.bread-crumb label,
.bread-crumb text {
  color: #101820;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

#haveCards {
  input[type='radio']:checked {
    background-color: #1d4289;
    border: 1px solid #000;
  }

  input[type='radio'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    outline: none;
    box-shadow: inset 0 0 0 2px #fff;
    border: 2px solid $secondary-font;
  }
}

.agent-card-head {
  justify-content: space-between;
  border-bottom: 1px solid #d8dbe0;
  padding: 0.75rem 1.25rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  //background-color: $background-white;
  background-color: #f1f1f1;
  min-width: 15.6rem;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  left: -15.5rem;
  padding: 1rem;
  //border: 1px solid #d8dbe0;
  //border:1px solid $background-white;
  border-radius: 0.4rem;
}

.dropdown-content i {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #ababab;
}

.dropdown-content i:active {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #236da8;
}

.dropdown-content i:enabled {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #236da8;
}

#menudiv {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  label {
    text-align: left;
    width: 70%;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary-font;
  }
}

#menuicondiv {
  display: flex;
  justify-content: space-evenly;
  width: 30%;
}

.cursor-pointer {
  cursor: pointer;
}

.color-red {
  color: $color-invalid !important;
}

#removeItem {
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
}

/* shopping cart icon and badge */

#cartDropDown {
  min-height: 100%;
}

.shoppingCart {
  width: 450px;
  padding: 1.2rem !important;
  background-color: $light-blue;
  border-radius: 8px;
  border-color: #ededed;
  border-top-color: transparent;
  position: relative;
  margin-top: -5px !important;
  max-height: 420px;
  overflow-y: auto;
  font-family: 'Lato', sans-serif;

  * .arrow-up {
    position: absolute;
    top: -12px;
    right: 14px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $light-blue;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $background-body;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-lines;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $tertiary-font;
  }

  > p {
    font-size: 1.5rem;
    color: $header-font;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .automate {
    font-size: 1rem;
    font-weight: bold;
    color: $primary-action;
  }

  .lead-sec {
    margin-bottom: 1.25rem;

    p {
      font-weight: 700;
      margin-bottom: 0.3rem !important;

      svg {
        margin-right: 8px;
      }
    }
  }

  .scd-row {
    display: flex;
    justify-content: space-between;
    background-color: $background-white;
    padding: 10px;
    border-radius: 4px 4px 0 0;

    .lead-type {
      width: 58%;
      color: $primary-font;
      white-space: break-spaces;

      span {
        margin-left: 6px;
        color: $tertiary-font;
      }
    }

    .lead-count {
      width: 20%;
      color: $primary-font;
    }

    .lead-price {
      width: 22%;
      color: $tertiary-font;
      display: flex;
      justify-content: space-between;

      span:first-child {
        text-align: right;
      }

      span:last-child {
        cursor: pointer;
        color: $primary-action;
      }
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}

.btn-row {
  display: flex;
  justify-content: flex-end;
}

.new-btn {
  max-width: 100%;
  height: 40px;
  outline: 0 !important;
  padding: 0.3rem 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 3px;
}

.light-btn {
  background: $background-white;
  color: $primary-action;
  border: 1px solid $primary-action;

  &.transparent-btn {
    background-color: transparent;
    border-color: transparent;
  }
}

.dark-btn {
  background: $primary-action;
  color: $background-white;
  border: 1px solid $primary-action;

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  &.btn-warn {
    background: $warning-500 !important;
    color: $primary-font;
    border-color: $warning-500;
  }

  &.btn-warn:active {
    background: $warning-500 !important;
    box-shadow: 0 0 0 0.2rem rgba($warning-bg, 0.2);
    color: $primary-font !important;
    outline: none !important;
  }
}

.shoppingCart .btn-row {
  @include respond-to(md) {
    flex-direction: column;

    .light-btn {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.c-header-nav .c-header-nav-link svg {
  width: 22px;
  height: 22px;
  color: $background-white;
}

.badge-warning {
  font-size: 11px;
  background-color: $background-body;
  color: $primary-font;
}

.c-header-nav .c-header-nav-link .badge {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  right: 3px;
  left: auto !important;
}

@media screen and (max-width: 420px) {
  .c-header-nav .c-header-nav-link .badge {
    right: 4px;
  }
}

.c-header-nav .c-header-nav-link .notifyBadge {
  right: 10px;
}

@media screen and (max-width: 420px) {
  .c-header-nav .c-header-nav-link .notifyBadge {
    right: 4px;
  }
}

.c-header-nav .c-header-nav-link {
  padding: 15px;
  text-decoration: none !important;
  color: $background-white !important;

  i {
    color: $background-white;
  }
}

@media screen and (max-width: 400px) {
  .c-header-nav .c-header-nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.c-avatar {
  height: 26px;
}

.sidebarIcons {
  color: white !important;
  font-size: 1.4rem;
}

.mailText {
  color: #236da8;
}

.primaryBtn {
  color: #236da8;
}

.flexEnd {
  justify-content: flex-end;
}

.noteText {
  color: red;
}

tr.highlight-reminder {
  background-color: lightgray;
}

.read-or-hide {
  cursor: pointer;
  text-decoration: underline;
}

.note-view-page {
  margin-top: 1.125rem;

  > div {
    padding: 0;
  }
}

.notAllowedButton {
  cursor: not-allowed !important;
}

.custom-bell {
  .c-icon {
    width: 1.35rem !important;
    height: 1.35rem !important;
  }
}

.crsr-default {
  cursor: default;
}

.table-assets tr td {
  border-bottom: 1px solid #e9e5e5;
}

.table-assets tr:last-child td {
  border-color: transparent;
}

@media screen and (max-width: 767px) {
  .table-assets tbody td:first-child {
    padding-top: 5px;
  }

  .table-assets tr td {
    width: 100%;
  }

  .table-assets tr:last-child td {
    border-bottom: 1px solid #e9e5e5;
  }
}

.campaign-review-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: $header-font;
  margin-bottom: 0px;
}

.edit-icon {
  color: #4b4b4c;
}

.campaign-review-value {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #3c4b64;
}

.lead-cash {
  &.noWarning {
    color: $green;
  }

  &.showWarning {
    color: $error-500;
  }
}

.campaign-bottom-row {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.75;
  color: #4b4b4c;
  letter-spacing: 0;

  .regular {
    font-weight: 400;
  }

  i {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
  }

  .col {
    display: flex;
    justify-content: space-between;
    max-width: 350px;

    span {
      text-align: right;
    }
  }

  .lead-price-value {
    font-weight: 400;
  }

  &:last-child {
    font-weight: normal;
    margin-top: 10px;
    font-size: 14px;
  }
}

.coupon-cost {
  color: #2371a4;
}

.css-9gakcf-option {
  background-color: $background-white !important;
  color: $secondary-font !important;
}

.dropdown-menu .dropdown-item {
  text-decoration: none;
}

.react-datepicker {
  display: flex !important;
  flex-direction: column;

  &__current-month.react-datepicker__current-month--hasYearDropdown,
  &__current-month--hasMonthDropdown {
    display: none;
  }

  &__navigation.react-datepicker__navigation--previous,
  &__navigation.react-datepicker__navigation--next {
    display: none;
  }

  &__month-read-view--selected-month,
  &__year-read-view--selected-year {
    font-weight: bold;
  }
}

.inputcalendarIcon {
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
  color: $primary-main;
}

.inputcalendarIcon.custom-filter-date {
  right: 7%;

  @include respond-to(sm) {
    right: 3%;
  }
}

.inputcalendarIcon.custom-edit-date {
  right: 1%;
}

.invalidDate {
  color: red;
  font-size: 12px;
  font-weight: 600;
}

.main {
  font-weight: bold;
  margin-bottom: 12px;
  width: 100%;
}

.sub {
  width: 100%;
}

.times-icon {
  width: auto;
  text-align: right;
  cursor: pointer;

  svg {
    color: $primary-action;
  }
}

.accordion-content {
  font-size: 12px;
  margin-left: 22px;
}

.delete-icon {
  font-size: 12px;
  color: #6c747b;
}

.content-seperator {
  border: 0.2px solid transparent;
  border-width: 0 0 0.2px 0;
  margin-top: 6px;
  margin-bottom: 10px;
}

.bannerclip {
  max-height: 305px;
  height: 305px;
  width: 100%;
  object-fit: cover;
  object-position: 0% 41%;
}

.btn-login {
  display: inline-block;
  color: $background-white;
  background: #236c9e;
  border: 2px solid #236c9e;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: calc(0.5em - 2px) 0.75em;
  border-radius: 0.25em;
  cursor: pointer;
  // min-width: 11.75rem;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-login:hover {
  color: $background-white;
}

.btn-login svg {
  fill: $background-white;
}

.c-footer {
  border-top-color: transparent !important;
}

.c-footer a {
  color: #3c4b64;
  text-decoration: none;
}

.c-footer a:hover {
  text-decoration: underline;
}

.accordion-review {
  width: 100%;
}

.edit-on-review {
  cursor: pointer;
}

.backtotop {
  position: fixed;
  right: 0;
  bottom: 15%;
  z-index: 1;
  cursor: pointer;
  color: #fff;

  @include respond-to(sm) {
    right: 25px;
    bottom: 5%;
  }
}

.inlinescrollTotopBtn {
  display: inline;
  width: 100%;
  background: $primary-action;
  padding: 13px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @include respond-to(sm) {
    height: 43px;
    width: 43px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    display: inline;

    @include respond-to(sm) {
      display: none;
    }
  }
}

.fs-18px {
  font-size: 18px;
}

.fs-16px {
  font-size: 16px;
}

.nonescrollTotopBtn {
  display: none;
  width: 100%;
  background: #2371a4;
  padding: 8px;
  font-size: 13px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

.ml-5px {
  margin-left: 5px;
}

.admin-notifications-bell .dropdown-menu .dropdown-item,
.admin-notifications-bell .dropdown-menu .dropdown-item a {
  text-decoration: none !important;
  color: #2370a4;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.admin-notifications-bell .dropdown-menu .dropdown-item:active {
  color: white;
}

.disabledAdminBell {
  cursor: pointer;
  color: #7f7f8a;
}

.enabledAdminBell {
  cursor: pointer;
  color: #2371a4;
}

.w-150px {
  width: 150px;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-title h3 {
  color: $header-font;
  margin-bottom: 1rem;
  letter-spacing: 0px;
}

.notification__tooltip .tooltip-inner {
  background-color: #fff;
  color: $primary-action;
  font-size: 14px;
  font-weight: 400;
  max-width: 300px;
  margin-top: 5px;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: -5px;
  border-width: 0 0.6rem 0.6rem;
  border-bottom-color: #fff;
}

.missingupline {
  color: #d32028;
}

.bgc-uplinemissing {
  background-color: $background-error;
}

.denymodal {
  top: 25%;
  left: 5%;
}

.denymodal-content {
  max-width: 512px;
  padding: 20px;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
}

.denymodal-content h2 {
  font-size: 25px;
  letter-spacing: -0.01em;
  color: #595959;
  font-weight: 700;
}

.denymodal-span {
  font-weight: 400;
  color: #595959;
  font-size: 20px;
  text-align: center;
}

.custom_wrapper {
  min-width: 80%;
}

#progressbar {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  z-index: 0;
  letter-spacing: 0;
}

#progressbar li span {
  width: 50%;
  display: inline-block;
}

#progressbar li {
  list-style-type: none;
  color: gray;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;

  &:first-child {
    width: 15%;

    span {
      width: 53%;
      display: flex;
      justify-content: center;
      padding-top: 5px;
    }
  }
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: transparent;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 5px auto;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 10px;
  background: lightgray;
  position: absolute;
  left: -50%;
  top: 6px;
  z-index: -1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:before {
  margin: 0 15%;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #2371a4;
  color: transparent;
}

.color-black {
  color: #000;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--scroll {
  margin-right: 25px;
}

.h-35px {
  height: 35px;
}

.launch__payment_info {
  font-size: $fs-12;
  font-style: italic;
  letter-spacing: -0.01em;
  font-weight: 400;
  color: #7f7f8a;
}

.no-border {
  border: none !important;
}

.payment_modal__header {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #595959;
  padding-bottom: 20px;
}

.payment_modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  padding-top: 30px;
  padding-bottom: 30px;
}

.campaign {
  margin-top: 1.25rem;
}

@media screen and (max-width: 1833px) and (min-width: 1750px) {
  #progressbar li:first-child span {
    width: 60%;
  }
}

@media screen and (max-width: 1749px) and (min-width: 1600px) {
  #progressbar li:first-child span {
    width: 63%;
  }
}

@media screen and (max-width: 1599px) and (min-width: 1401px) {
  #progressbar li:first-child span {
    width: 77%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1281px) {
  #progressbar li:first-child span {
    width: 80%;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1106px) {
  #progressbar li:first-child span {
    width: 84%;
  }
}

@media screen and (max-width: 1470px) and (min-width: 1390px) {
  .custom_wrapper {
    min-width: 78%;
  }
}

@media screen and (max-width: 1389px) and (min-width: 1116px) {
  .custom_wrapper {
    min-width: 71%;
  }
}

@media screen and (max-width: 1115px) and (min-width: 1106px) {
  .custom_wrapper {
    min-width: 71%;
  }

  .campaign button {
    width: 118px;
    margin-right: 0.25rem;
  }
}

@media screen and (max-width: 1105px) {
  .custom_wrapper {
    min-width: 100%;
  }

  #progressbar li:first-child {
    width: 25%;
  }

  #progressbar li:first-child span {
    width: 50%;
    display: inline-block;
  }

  #progressbar li:first-child:before {
    margin: auto;
  }

  .campaign-navigation {
    flex-direction: column !important;
  }

  .campaign {
    width: 110px;
    margin-right: 20px !important;
  }
}

.formDiv .quickSearch-icon .fa-search {
  font-weight: 100;
  color: #b1b2b6;
}

.custom-li-help {
  margin-right: 15px;
  color: $background-white;
  font-weight: 400;
}

.custom-footer-help {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.disclaimer-para {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: $color-title;
}

.record-callscript-btn {
  border-radius: 7px;
  border: 2px solid $background-white;
  background-color: transparent;
  color: $background-white;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  i {
    color: $background-white;
  }
}

.callstoggle-confirmation-dialog {
  width: 450px;
  max-height: 100%;
  background-color: #f4f8fb;
  top: 15%;
  margin: auto;
  border-radius: 8px;
}

@media screen and(max-width: 486px) {
  .callstoggle-confirmation-dialog {
    width: 360px;
  }
}

.callstoggle-confirmation-content {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.callsdialogcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 20px;

  &--title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: $header-font;
    margin-bottom: 10px;
  }

  &--text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: $primary-font;
  }

  &--number {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: $background-header-nav;
    margin-bottom: 5px;
  }

  &__block {
    min-height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 0px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-lines;
      padding-bottom: 5px;
      margin-top: 5px;
      padding-left: 10px;
      padding-right: 10px;

      &--textfield {
        width: 100%;
        border: 2px solid #d8dbe0;
        border-radius: 5px;
        height: 35px;
        font-size: 16px;
        color: #3c4b64;
      }

      &__a {
        display: flex;
        align-items: center;

        &__icon {
          height: 30px;
          width: 30px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f4f8fb;
          color: $primary-action;

          &--rtt {
            transform: rotate(90deg);
          }
        }

        &--header {
          padding-left: 10px;
          margin-bottom: 0px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: $secondary-font;

          @media screen and (max-width: 601px) {
            font-size: 14px;
          }
        }

        &--text {
          margin-bottom: 0px;
          padding-left: 10px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: $tertiary-font;
          text-align: center;
        }
      }

      &--arrow {
        padding-right: 10px;
        cursor: pointer;
      }
    }

    &__buttons {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.medicare_link {
  text-decoration: none;
  color: $primary-action;
}

.action-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;

  .hl-edit {
    i {
      display: inline-block;
      margin-right: 8px;
      letter-spacing: 0;
    }

    svg {
      font-size: 1.125rem;
      cursor: pointer;
      color: #2370a4;
    }

    img {
      max-width: 150px;
      margin-left: 8px;
      display: inline-block;
    }
  }
}

.save-btn-wrapper {
  &.only-save {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.card-deck.health-card {
  .inputDropdown {
    > div {
      div:last-child {
        > div {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .h1-editTitle {
    width: 100%;
    text-align: center;
  }

  .action-row {
    .hl-edit {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;
    }
  }

  .save-btn-wrapper {
    width: 100%;

    &.only-save {
      flex-direction: column;
    }
  }

  #progressbar li {
    font-size: 11px;
  }
}

.rc-modal {
  .modal-dialog {
    max-width: 650px !important;
    width: 100%;
    top: 25%;
  }

  .modal-header {
    border-color: transparent;
  }

  .modal-body {
    padding: 0 3rem 4rem;

    .rc-header {
      padding-left: 15px;
      font-size: 1.25rem;
    }

    .rc-form {
      display: flex;
      margin: 2rem 0 1.5rem;
      justify-content: flex-start;
      flex-direction: row;
    }

    .rc-disclaimer {
      text-align: center;
      margin-bottom: 4rem;
      font-size: 0.75rem;
    }

    .rc-modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .btn {
        padding: 0.5rem 2rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .rc-modal {
    .modal-body {
      padding: 0 3rem 4rem;

      .rc-form {
        flex-direction: column;

        label {
          margin-bottom: 1rem;
        }
      }

      .rc-disclaimer {
        margin-bottom: 1rem;
      }

      .rc-modal-footer {
        .btn {
          margin-top: 2rem;
        }
      }
    }
  }
}

.toggle-ribbon {
  background-color: $primary-action;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -2rem -2rem 2rem -2rem !important;
  animation: 'show-ribbon' 0.5s ease-in;

  &--text {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0px;
    color: $background-white;
  }

  @media screen and (max-width: 715px) {
    margin: -2rem -1rem 2rem -1rem !important;

    &--text {
      font-size: 12px;
    }
  }
}

.vr {
  border-left: 1px solid $background-white;
  height: 35%;
}

.bu-bck-btn {
  border: 1px solid #2371a4 !important;
  outline: 0 !important;
  background: transparent !important;
  color: #2371a4 !important;
}

.mailer-icon {
  margin-top: 4px;
  margin-right: 8px;
}

.icon-wrapper div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 0.55rem;
    color: #2371a4;
  }

  svg {
    width: 21px !important;
    height: 21px;

    + span {
      margin-top: 2px;
    }
  }
}

.solid-alert {
  color: $primary-action !important;
}

.alert_text {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  margin-right: 5px;
}

.menu-bar-icon {
  font-size: 24px;
}

.menu-bar-icon-label {
  font-size: 10px;
}

.menu-bar-icon-container {
  cursor: pointer;
  color: #2371a4;
}

.disabled {
  cursor: not-allowed;
  color: #c4c4c4;
}

//eGifts vouchers
h3.egift-title {
  margin-bottom: 1rem !important;
}

.elc-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.redeem-gift {
  font-size: 0.8rem;
  letter-spacing: 0;
  margin-left: 2rem;
  cursor: pointer;
  color: #2370a4;
}

.egift_list_wrapper {
  background-color: #fff;
  border: 1px solid #d8dbe0;
  padding: 15px 5px;

  .egift-sub-title {
    font-weight: 700;
    font-size: 1.125rem;
    color: $primary-action;
    display: flex;
    flex-direction: row;

    .tab_wrapper {
      display: flex;
      font-size: 0.895rem;
      align-items: flex-end;
      letter-spacing: 0;
      margin-left: 1rem;
    }

    .tab_life,
    .tab_health,
    .tab_recruitment {
      padding: 0 10px 5px;
      border-bottom: 3px solid;
      border-color: transparent;
      cursor: pointer;
      font-weight: normal;
      color: #000;

      &.active {
        color: $primary-action !important;
        border-color: $primary-action;
        font-weight: bold;
      }
    }

    .tab_life span,
    .tab_health span,
    .tab_recruitment span {
      margin-left: 5px;
    }

    .separator {
      margin: 0 1.125rem 0.5rem;
      font-size: 1.25rem;
      color: $border-lines;
    }
  }

  .info-icon {
    padding: 0 5px;
    font-size: 14px;
    width: inherit;
  }
}

.dropdown-wrap {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0 30px;

  .dropdown-type {
    width: 200px;
    margin-right: 20px;
  }
}

.no-border td {
  border-bottom-color: transparent !important;
}

.voucher-table {
  margin-top: 1rem;

  .thead-light th {
    background-color: $primary-action;
    color: #fff;
  }

  tbody tr td {
    letter-spacing: 0;
    vertical-align: middle;

    .miw {
      width: 105px;
      display: inline-block;
    }
  }

  tbody tr td:last-child {
    text-align: right !important;

    p {
      margin-bottom: 0 !important;
    }
  }

  .eth {
    margin-bottom: 0 !important;

    span {
      width: 100%;
      display: inline-block;
      font-size: 0.8rem;

      &.noValue {
        display: none;
      }
    }
  }

  .agnt {
    font-style: normal;
  }
}

.expanded-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #efefef;
  padding: 1rem;

  > div {
    width: 20%;

    > div {
      font-size: 0.895rem;
      text-align: left !important;
      font-style: normal;
      letter-spacing: 0;
      color: $primary-font;
      padding-right: 0.8rem;

      span {
        float: left;
      }

      .ellipsis {
        display: flex;
      }
    }
  }

  .evh {
    font-style: normal;
    font-size: 0.75rem;
    margin-bottom: 0.2rem !important;
  }
}

@media screen and (max-width: 767px) {
  .redeem-gift {
    display: inline-block;
    margin-left: 0;
    width: 100%;
  }

  .elc-top-row {
    flex-direction: column;
  }

  .egift_list_wrapper {
    .egift-sub-title {
      flex-direction: column;

      .tab_wrapper {
        margin-top: 1rem;
        justify-content: center;
      }
    }
  }

  .search-wrap {
    display: flex;
    flex-direction: column;

    .dropdown-wrap {
      max-width: 100%;

      .dropdown-type {
        width: 80%;
      }
    }
  }

  .expanded-div {
    > div {
      > div {
        padding-right: 0.3rem;
      }
    }
  }

  .miw {
    display: flex;
    flex-direction: revert;
    justify-content: flex-start;
    align-items: center;
  }
}

.lead-select-checkbox {
  height: 13px !important;
  width: 13px !important;
}

.width-100px {
  width: 100px !important;
}

.revokeModal {
  top: 20%;

  .modal-body {
    padding: 3rem 2rem;
  }
}

.revoke-popup {
  div {
    font-size: 1.125rem;
    text-align: center;
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
    font-size: 1rem;
  }
}

.revoke-btn-wrap {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 1rem;
    width: 40%;

    &.revoke-btn {
      background-color: #ea5455 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .revoke-btn-wrap {
    flex-direction: column;

    button {
      margin: 1rem 0 1rem;
      width: 100%;
    }
  }
}

.modal-assignLeadHealthErr {
  top: 20%;
  max-width: 700px;

  .modal-body {
    padding: 3rem;

    p {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 1rem;
    }

    .err-content > p {
      font-size: 1rem;
      text-align: left;
      margin-bottom: 0.5rem;
      display: flex;

      span:first-child {
        width: 40px;
      }

      span:first-child:before {
        display: inline-block;
        content: '';
        -webkit-border-radius: 0.375rem;
        border-radius: 0.375rem;
        height: 0.75rem;
        width: 0.75rem;
        margin-right: 0.5rem;
        background-color: darkgray;
      }
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: 2rem;
      max-width: 300px;
    }
  }
}

.map__geography__info {
  position: relative;
  display: inline-block;
  z-index: 1;
  top: 83%;
  left: 1%;
  color: $background-white;
  background-color: $primary-font;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 12px 10px;
  opacity: 0.8;

  &--title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &--data {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.header__subtitle {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $background-white;
}

.border-invalid {
  border: 1px solid $color-invalid !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.disable-bg {
  background-color: $primary-font-inactive !important;
  opacity: 0.35;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.toggle__demo__modal {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  max-width: 350px;

  @media screen and (max-width: 478px) {
    top: 150px;
  }

  &--header {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $header-font;
  }

  &__body {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary-font;

    &--link {
      color: $primary-action;
      font-weight: 500;
    }
  }
}

.demoIdentifyingDiv {
  border: 2px solid $demo-border-color;
  padding: 3px;
  border-radius: 5px;
}

.pointingArrow {
  position: absolute;
  top: -10px;
  right: -80px;
}

.resize-none {
  resize: none;
}

@media screen and (max-width: 767px) {
  .custom-li-help {
    display: none;

    + .vr {
      display: none;
    }
  }

  .c-header img {
    height: 22px;
  }
}

@media screen and (max-width: 601px) {
  .shoppingCart {
    max-width: 450px;
    width: 100%;
    min-width: 350px;
  }

  .c-header.c-header-fixed {
    top: 55px;
  }
}

.bg-lead-white {
  background-color: $background-white;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}

.greet-wrapper {
  display: flex;
  position: absolute;
  color: $background-white;
  max-width: 700px;
  max-height: 700px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 70%;
  top: -15rem;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;

  span {
    margin-top: 2rem;
    margin-left: 5rem;
    overflow-wrap: break-word;
    inline-size: 400px;
    display: none;
  }

  &.morning {
    background: $gradient-morning;
  }

  &.afternoon {
    background: $gradient-afternoon;
  }

  &.evening {
    background: $gradient-evening;
  }

  @include respond-to(highres) {
    left: 66%;
  }

  @include respond-to(largeres) {
    left: 60%;
  }

  @include respond-to(xxl) {
    left: 50%;
  }

  @include respond-to(xl) {
    left: 45%;
  }

  @include respond-to(md) {
    left: 0;
    top: 0;
    font-size: 1.3rem;
    background: none !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    border-radius: 0;
    max-width: 800px;
    max-height: 170px;
    width: 100%;
    height: 100%;

    span {
      margin-top: 0;
      margin-left: 1rem;
      display: block;
      bottom: 2rem;
      position: relative;
      text-align: left;
      font-size: 1.5rem;
    }
  }
}

.order-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0.5rem;

  .greet-txt {
    font-size: 1.8rem;
    color: $background-white;
    position: relative;

    @include respond-to(md) {
      display: none;
    }
  }
}

.c-sidebar-nav-item {
  @include respond-to(md) {
    margin-left: 0.2rem;
  }
}

.red {
  color: $error-500 !important;
  text-align: end;
}

.table-footr {
  padding: 15px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.cart-calc {
  text-align: right;
  font-size: 1rem;

  > div {
    span {
      display: inline-block;
      width: 155px;

      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    span:first-child {
      color: #000;
      font-weight: 700;
    }

    span:last-child {
      width: 270px;
      margin-right: 2rem;
      color: $primary-font;

      @media (max-width: $breakpoint-md) {
        margin-right: 0;
        text-align: right;
      }
    }
  }

  .cart-total {
    font-size: 1.5rem;

    span:first-child {
      font-weight: 400;
      color: $header-font;
    }

    span:last-child {
      width: 265px;
      color: $header-font;
    }
  }
}

.order-summ {
  font-family: 'Lato', sans-serif;

  #removeItem {
    visibility: hidden;
  }

  .payment-sec {
    padding: 15px 25px;
  }

  .summ-container {
    font-size: 1rem;

    > div {
      line-height: 1.5;

      span:last-child {
        color: $primary-font;
      }
    }
  }
}

.cgray {
  color: $tertiary-font !important;
}

.arrow-sec {
  &:active,
  &:focus {
    outline-offset: 0;
    outline: none;
  }
}

.m-mb-150 {
  @include respond-to(md) {
    margin-bottom: 150px;
  }
}

.sm-d-none {
  display: inline;

  @include respond-to(sm) {
    display: none;
  }
}

.min-w-100 {
  min-width: 100% !important;
}

.mobile_border_bottom {
  border: none;

  @include respond-to(xl) {
    border-bottom: 1px solid $border-lines !important;
    border-radius: 0px;
  }

  &:last-child {
    @include respond-to(xl) {
      border-bottom: 1px solid $border-lines !important;
      border-radius: 0px;
    }
  }
}

.close {
  opacity: 1 !important;
}

.bg-action-selected {
  background: $background-body !important;
}

.custom-multi-value {
  background: $background-default;
  border-radius: 4px 0 0 4px !important;
  font-size: 14px;
  color: $primary-font;
  max-width: 250px;
}

.select-input {
  &__single-value {
    font-size: $fs-16 !important;
    font-weight: 400;
  }

  &__multi-value__label {
    font-size: 16px !important;
    font-weight: 400;
    padding-right: 6px !important;
  }

  &__option {
    color: $primary-font !important;
    font-size: $fs-16 !important;
    font-weight: 400;

    &--is-selected {
      background-color: $background-body !important;
    }
  }
}
