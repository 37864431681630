.transactionshistory {
  margin-top: -15px;

  &__selectedOptions {
    display: flex;
    align-items: center;
    max-width: 75%;
    overflow: hidden;
    padding: 10px 0;

    &:hover{
      overflow: auto;
    }

    &::-webkit-scrollbar {
      height: 7px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: 2px;
      background-color: $background-pill;
      border-radius: 5px;
      cursor: pointer;
    }

    &__option {
      margin: 0px 3px;
      padding: 2px 4px;
      border-radius: 3px;
      background-color: $background-pill;
      color: $primary-font;
      display: flex;

      width: 100%;
      align-items: center;
      justify-content: space-between;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
        margin-right: 5px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.06em;
        color: $primary-font;
        display: flex;
        align-items: center;

        span{
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $primary-font;
        }
      }

      &--icon {
        padding: 2px;
        border-radius: 3px;
        color: $primary-action;

        &:hover {
          color: $error-500;
          transform: scale(1.1);
        }
        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &__filter__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    @include respond-to(sm) {
      justify-content: center;
    }
  }

  &__card {
    background: $background-white;
    border: 0.5px solid $border-lines !important;
    border-radius: 8px !important;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &--title {
        font-size: 24px;
        font-weight: 400;
        align-items: left;
        margin-bottom: 0;
        text-align: left;
        color: $header-font;

        @include respond-to(xl) {
          font-size: 18px;
        }
      }
    }
  }

  &--title {
    font-size: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  hr {
    margin: 0.5rem 0 0.5rem;
    border-color: $primary-action;
  }

  &__orderdetailscard {
    min-height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;

    &--header {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.01em;
      color: $primary-action;
    }

    &__search_filter {
      margin: 1rem 0 0 -1rem;
    }

    &__filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 10px;

      .transactionType {
        margin-right: 1rem;
      }
    }

    &__table {
      .table {
        .thead-primary {
          th {
            border: 0px;
            height: 50px;
            vertical-align: middle;
            background-color: $primary-action;
            color: $background-white;
          }

          th:first-child {
            border-top-left-radius: 0.4rem;
          }

          th:last-child {
            border-top-right-radius: 0.4rem;
          }
        }

        tbody {

          tr:first-child,
          tr:last-child {

            td:first-child,
            td:last-child {
              border-bottom-left-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            }
          }
        }

        @media only screen and (max-width: 1409px) {
          .thead-primary th {
            vertical-align: top;
          }
        }
      }
    }
  }
}

.transactiontype {
  width: auto;
  border-radius: 3px;
  background-color: $shaded-color;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;

  &__name {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.06em;
    color: $primary-font;
  }

  &__type {
    margin-right: 5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
}

.voucher-indicator {
  background: $voucher;
}

.cash-reload-indicator {
  background: $cashReload;
}

.realtime-campaign-indicator {
  background: $realtimeCampaign;
}
.ad-campaign-indicator {
  background: $adCampaign;
}

.refund-indicator {
  background: $refund;
}

.lead-order-indicator {
  background: $leadOrder;
}

.automated-order-indicator {
  background: $automatedOrder;
}

.bpl-campaign-indicator {
  background-color: $header-font;
}

.active-status {
  background-color: $cashReload;
}

.paused-status {
  background-color: $warning-500;
}

.canceled-status {
  background-color: $error-300;
}

.sorting {
  &__disabled {
    cursor: pointer;
    opacity: 0.6;
    color: $primary-action;
    font-size: 10px;
  }

  &--icon {
    color: $primary-action;
    font-size: 10px;
    cursor: pointer;
  }
}

.nodata-container {
  display: flex;
  align-items: center;
  background-color: $background-white;
  border-radius: 8px;
  padding-left: 40px;

  @include respond-to(sm) {
    padding-left: 0px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  &__block1 {
    flex: 0.5;

    @include respond-to(sm) {
      padding: 10px;
    }

    &__info {
      width: 80%;
      padding-left: 30px;

      @include respond-to(sm) {
        width: 100%;
        padding-left: 10px;
      }

      h2 {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        color: $header-font;

        @include respond-to(sm) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      p {
        margin-top: 15px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: $primary-font;

        @include respond-to(sm) {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }

    &__buttons {
      @include respond-to(sm) {
        padding-left: 10px;
      }

      &--linkBtn {
        @include respond-to(sm) {
          margin-top: 10px;
        }
      }
    }

  }

  &__block2 {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}

.error-color{
  color: $error-500;
}

.campaignName {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: $primary-font;
}

.order-recurrences {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;


  >* {
    @extend .ml-2;
  }

  // Remove ml-4 class from Options
  >.ml-2 {
    @if flex-wrap==wrap {
      @extend .ml-2;
    }
  }
}

.view-automate-orders-table{
  border-top: 1px solid $border-lines;
}

.w-180px > div span:last-child{
  width: 180px !important;
}

.max-height-40vh{
  max-height: 40vh;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $background-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $border-lines;
    border-radius: 10px;
  }
}

.cart-summary-calculations {
  text-align: right;
  font-size: 1rem;
  > div {
    span {
      display: inline-block;
      width: 155px;
    }
    span:first-child {
      color: #000;
      font-weight: 700;
    }
    span:last-child {
      width: 270px;
      margin-right: 2rem;
      color: $primary-font;
      @include respond-to(md){
        margin-right: 0;
      }
    }
  }
  .cart-total {
    font-size: 1.5rem;
    span:first-child {
      font-weight: 400;
      color: $header-font;
    }
    span:last-child {
      width: 265px;
      color: $header-font;
    }
  }
}