.no-results-container {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  @media (max-width: 768px) {
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 24px 0;
  }
}

@media (max-width: 768px) {
  .no-results-text {
    padding: 0px !important;
    width: 100% !important;
  }
  .no-results-img-container {
    width: 100% !important;
  }
}

.no-results-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-results-img {
  max-width: 100%;
  height: auto;
}
