//This file is created to write and reuse the utility classes that are used on application
/*
  Usage -> two types can be used below classes.
  //primary-font utility class created below and used on examples.
  1. By adding classnames directly to your HTML elements
     Ex: <p className="primary-font">Paragraph style 1</p>
  2. You can define your utility class names using placeholders and by using `@extend`
     Ex: <p className="test-paragraph">Paragraph style 2</p>
     style:
     .test-paragraph{
        @extend .primary-font;
     }
*/
@import './variables';
@import './mixin';

// Color utilities
.primary-font {
  color: $primary-font;
}

.secondary-font {
  color: $secondary-font;
}

.tertiary-font {
  color: $tertiary-font !important;
}

.header-font {
  color: $header-font;
}

.white-font {
  color: $background-white;
}

.error-500-font {
  color: $error-500 !important;
}

.error-main-font {
  color: $error-main;
}

.primary-font-inactive {
  color: $primary-font-inactive;
}

.primary-action-font {
  color: $primary-action !important;
}

.primary-action-disabled-font {
  color: $primary-action-disabled;
}

.note-warning-font {
  color: $note-warning-font;
}

.primary-action-onhover {
  color: $primary-action-onhover;
}

.header-nav-font {
  color: $background-header-nav;
}

.gradient-afternoon {
  color: $gradient-afternoon;
}

.bg-transparent {
  background-color: transparent;
}

.bg-primary-action {
  background-color: $primary-action !important;
}

.bg-primary-action-disabled {
  background-color: $primary-action-disabled;
}

.bg-error {
  background-color: $error-500;
}

.bg-white {
  background-color: $background-white;
}

.background-white {
  background-color: $background-white;
}

.bg-background-default {
  background-color: $background-default;
}

.color-title {
  color: $color-title;
}

.color-primary-main {
  color: $primary-main;
}

.title-blue-font {
  color: $title-blue !important;
}

.integrity-navy-font {
  color: $integrity-navy;
}

.gradient-afternoon-font {
  color: $gradient-afternoon;
}

// Typography utilities
.fs-h1 {
  font-size: 48px;
}

.fs-h2 {
  font-size: 32px;
}

.fs-h3 {
  font-size: 24px;
}

.fs-h4 {
  font-size: 18px !important;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-18 {
  font-size: 18px;
}

.fs-h5,
.fs-14 {
  font-size: 14px;
}

.fs-p,
.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fs-9 {
  font-size: 9px;
}

.ff-lato {
  font-family: 'Lato', sans-serif;
}

.d-flex {
  display: flex;
}

//flex utilities
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

//border utilities
.rounded-sm {
  border-radius: 3px;
}

.rounded-md {
  border-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-xl {
  border-radius: 25px !important;
}

.card-border {
  border: 1px solid $border-lines;
}

.border-top {
  border-top: 1px solid $border-lines;
}

.border-left {
  border-left: 1px solid $border-lines;
}

.border-bottom {
  border-bottom: 1px solid $border-lines;
}

.border-light-gray {
  border: 1px solid $integrity-gray-light-2;
}

.border-none {
  border: none;
}

.shadow-on {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.min-h-0 {
  min-height: 0 !important;
}

.w-100px {
  width: 100px !important;
}

.width-100 {
  width: 100% !important;
}

.w-80 {
  width: 80% !important;
}

.w-60 {
  width: 60% !important;
}

.height-48 {
  height: 48px;
}

.height-17 {
  height: 17px;
}

.width-max-content {
  width: max-content;
}

.background-none {
  background: none;
}

.popup-content {
  @extend .fs-20;
  @extend .flex-center;
}

//padding
.padding-sm {
  padding: 4px;
}

.padding-md {
  padding: 8px;
}

.padding-lg {
  padding: 12px;
}

.padding-xl {
  padding: 16px;
}

.margin-right-4 {
  margin-right: 4px;
}

.margin-24 {
  margin: 24px;
}

.padding-72 {
  padding: 72px;
}

.padding-l-0 {
  padding-left: 0;
}

.padding-0 {
  padding: 0;
}

.spin-5s {
  animation: spin 5s linear infinite;
}

.btn-height {
  height: 32px;
}

.input-height {
  height: 40px;
}

.width-250px {
  width: 250px;
}

.w-50-100 {
  width: 50% !important;
  @include respond-to(lg) {
    width: 100% !important;
  }
}

.card-on-hover {
  &:hover {
    box-shadow: 0px 3px 10px 0px $components-input-outlined-enabledBorder;
  }
}

.right-2p {
  right: 2% !important;
}

.input-outline-enabledBorder {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.margin-top-24 {
  margin-top: 24px;
}

//opacity
.opacity-75 {
  opacity: 0.75 !important;
}

.textarea-limited {
  resize: none;
}

.warning-icon-color {
  color: $warning-main;
}

.text-center {
  text-align: center !important;
}

.margin-left-auto {
  margin-left: auto;
}

.justify-items-end {
  justify-items: end;
}

.divider {
  border-bottom: 1px solid $divider;
}

.gap-16 {
  gap: 16px;
}

.no-text-decoration {
  color: inherit;
  text-decoration: none !important;
  &:hover {
    color: inherit;
    text-decoration: none !important;
  }
  &:focus {
    color: inherit;
    text-decoration: none !important;
  }
}
