// Variable overrides
$color-invalid: #cf4843;
$color-title: #7f7f8a;
$subtitle-color: #828ea1;
$label-color: #bbbbbb;
$focus-color: #ffff00;
$green: #0fba00;
$demo-border-color: #ff0dff;
$light-blue: #f4f8fb;
$warning-bg: #1f315f;

//state of the input colors
$button-disabled-color: #a0c4f8;

//border colors
$invalid-border: #d32028;

//border-radius
$border-radius: 8px;

//font-sizes
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-24: 24px;
$fs-48: 48px;

//Theme Colors Used for Importer
$border-light: #c7ccd1;
$main-text-color: #333333;
$muted-text-color: #8f99a3;
$page-bg-hover: #dee1e8;
$brand-text-color: #236c9e;
$brand-text-hover-color: #0079cc;
$brand-bg-color: #236c9e;
$brand-bg-hover-color: #0079cc;
$disabled-color: #a4aec1;
$disabled-contrast-color: #e8eaef;
$error-confirm-state-color: #f0514c;
$error-state-notification-color: #cf4843;
$error-confirm-state-background: #fff1f0;
$import-text-color: #3c4b64;
$shaded-color: #f5f6fa;

//Breakpoints
$breakpoint-xxsm: 380px;
$breakpoint-xsm: 450px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$breakpoint-largeres: 1600px;
$breakpoint-highres: 1900px;

//leadStatus Colors
$statusNew: #95b3ff;
$statusFirstCall: #89a6f1;
$statusSecondCall: #7c99e3;
$statusThirdCall: #708cd4;
$statusVicemail: #637fc6;
$statusText: #5772b8;
$statusDoorKnock: #4b66aa;
$statusAppointment: #3e589b;
$statusSubmitted: #324c8d;
$status501: #194371;
$status504: #b2ccdb;
$status900: purple;
$status901: pink;

//coupon status colors
$filterColunm: rgba(0, 158, 21, 0.5);
$components-chip-defaultEnabledBorder: #bdbdbd;

//Color Variables Based on Referenced Design System(version-1)
$primary-action: #0052ce;
$primary-action-onhover: #2175f4;
$primary-action-disabled: #a0c4f8;
$background-pill: #d8e6f9;
$background-body: #f1faff;
$header-font: #002d72;
$background-header-nav: #051d43;
$primary-font: #434a51;
$secondary-font: #000000;
$tertiary-font: #717171;
$primary-font-inactive: #aaaaaa;
$border-lines: #dfdedd;
$row-highlight: #ededee;
$background-error: #fff1f1;
$background-modal: #f9fafb;
$background-white: #ffffff;
$icon-selection-color: #f4f6fa;
$success-500: #7fd7b2;
$warning-500: #ffd390;
$error-500: #ff4444;
$error-200: #ffdcdc;
$success-200: #d8f5d9;
$warning-200: #fff2dd;
$error-300: #ff7676;
$gradient-morning2: #72d3e8;
$gradient-afternoon2: #76a7de;
$gradient-evening2: #5b3dce;
$gradient-sunset1: #fdfafa;
$gradient-sunset2: #ff483d;
$diamond: #4f98be;
$titanium: #757575;
$platinum: #898b8d;
$leadCENTER-Gold: #a99057;
$silver: #b9b7bd;
$bronze: #8c5846;
$leadOrder: #a8cdf6;
$voucher: #bb77ee;
$refund: #c1c1c1;
$cashReload: #5ad76b;
$realtimeCampaign: #0267ff;
$adCampaign: #ffde00;
$automatedOrder: #8893f6;
$carousel-dot: #d9d9d9;
$section-blue: #f2f6fd;
$gradient-morning: #6a95ff;
$gradient-afternoon: #4178ff;
$primary-main: #4178ff;
$gradient-evening: #1457ff;
$note-warning-font: #e28a05;
$title-blue: #052a63;
$success-main: #009e15;
$error-main: #c81e27;
$text-disabled: #9e9e9e;
$background-warning: #fcf2e8;
$background-default: #f1f1f1;
$warning-main: #f56600;
$integrity-navy: #052a63;
$integrity-gray-light-1: #f1f1f1;
$integrity-gray-light-2: #dddddd;
$info-main: #49648b;
$components-input-outlined-enabledBorder: rgba(0, 0, 0, 0.23);
$drop-shadow: rgba(0, 0, 0, 0.161);
$components-alert-success-background: #e5faeb;
$components-alert-info-background: #f1faff;
$components-alert-error-background: #fbdede;
$new-blue: #23418e;
$dark-blue: #525252;
$components-rating-activeFill: #ffb400;
$divider: #cccccc;
$action-active: rgba(0, 0, 0, 0.56);
