.status-processing {
  background-color: $warning-500;
}

.status-processed {
  background-color: $cashReload;
}

.status-canceled {
  background-color: $primary-font-inactive;
}

//BPL Statuses

// Active
.status-10 {
  background-color: $cashReload;
}
// Pending Approval by Manager
.status-20 {
  background-color: $warning-500;
}
// Pending Approval by LeadCENTER Administrator
.status-21 {
  background-color: $warning-500;
}
// Canceled
.status-30 {
  background-color: $error-500;
}
// Denied
.status-40 {
  background-color: $tertiary-font;
}